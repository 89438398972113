<template>
  <div
    class="mx-auto"
    style="max-width: 800px"
  >
    <div v-if="edit">
      <div class="mb-1 d-flex align-items-center justify-content-between">
        <span>Содержимое вопроса</span>
        <b-button
          variant="flat-danger"
          size="sm"
          @click="$emit('delete')"
        >
          <feather-icon
            class="mr-50"
            icon="XIcon"
          />
          Удалить вопрос
        </b-button>
      </div>
      <b-form-group>
        <b-form-input
          v-model="content.question"
          type="text"
          placeholder="Укажите что нужно сделать в упражнении"
        />
      </b-form-group>
    </div>
    <div
      v-else
      class="text-center"
    >
      <h2>{{ exercise.name }}</h2>
      <p class="font-medium-1">
        {{ content.question }}
      </p>
    </div>

    <exercise-button
      :content="content"
      :edit="edit"
      :type="exercise.exercise_types_id"
      @updated="(value) => $emit('updated', value)"
    />
  </div>
</template>

<script>
import { BButton, BFormGroup, BFormInput } from 'bootstrap-vue'
import ExerciseButton from '@/components/exercise/contents/type/ExerciseButton.vue'

export default {
  name: 'ExerciseContent',
  components: {
    ExerciseButton,
    BButton,
    BFormGroup,
    BFormInput,
  },
  props: {
    index: {
      type: Number,
      default: null,
    },
    exercise: {
      type: Object,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {

  },
  mounted() {

  },
}
</script>

<style scoped>

</style>
