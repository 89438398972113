<template>
  <div :class="wrapperClass">
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center flex-fill"
    >
      <b-spinner
        variant="primary"
        label="Spinning"
        style="width: 2rem; height: 2rem;"
      />
    </div>
    <div
      v-if="iframeUrl"
      :key="docKey"
    >
      <b-embed
        :class="contentClass"
        :src="iframeUrl"
      />
    </div>
  </div>
</template>

<script>
import { BSpinner, BEmbed } from 'bootstrap-vue'

export default {
  name: 'StoreDocument',
  components: {
    BSpinner, BEmbed,
  },
  props: {
    storeId: {
      type: [String, Number],
      default: null,
    },
    contentClass: {
      type: [String, Object],
      default: null,
    },
    wrapperClass: {
      type: [String, Object],
      default: null,
    },
  },
  data: () => ({
    src: null,
    loading: false,
    docKey: 0,
  }),
  computed: {
    iframeUrl() {
      return this.src ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${this.src}` : null
    },
  },
  watch: {
    storeId: {
      handler() {
        if (this.storeId) this.getDocUrl()
      },
    },
  },
  mounted() {
    this.getDocUrl()
  },
  methods: {
    async getDocUrl() {
      this.loading = true
      const { full_url: url } = await this.$lazyHttp.storeGet(this.storeId)
      this.src = url
      this.$nextTick(() => {
        this.docKey += 1
      })
      this.loading = false
    },
  },
}
</script>

<style  lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.embed-responsive {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  aspect-ratio: 1/1.4142;
  border: none;
  border-radius: $border-radius;

}
</style>
