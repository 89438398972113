<template>
  <div class="d-flex align-items-center">
    <b-button
      v-if="!fullscreen && expandable"
      variant="flat-secondary"
      class="btn-icon mr-1"
      :title="expanded ? 'Сжать редактор' : 'Расширить редактор'"
      @click="$emit('toggleExpand')"
    >
      <feather-icon
        size="18"
        :icon="expanded ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'"
      />
    </b-button>

    <b-button
      v-else
      variant="flat-secondary"
      class="btn-icon mr-1"
      title="Назад"
      @click="goBack"
    >
      <feather-icon
        class="mr-50"
        icon="ArrowLeftIcon"
      />
      Назад
    </b-button>

    <template v-if="!step.home_tasks_id && hometaskId && !editing">
      <b-button
        variant="flat-info"
        @click="$emit('addToHometask'); addingToHometask = true"
      >
        <template v-if="!addingToHometask">
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span>Добавить в ДЗ</span>
        </template>
        <template v-else>
          <b-spinner small />
        </template>
      </b-button>
    </template>

    <div
      v-if="step.id && user.id !== step.user_id && !hometaskId"
      class="d-flex align-items-center ml-50"
    >
      <span class="mr-50">Оцените материал:</span>
      <b-form-rating
        v-if="!loadingRating"
        v-model="userRating.rating"
        no-border
        variant="warning"
        inline
        precision="2"
        class="mr-50"
        @change="saveRating"
      />
      <skeletor
        v-else
        width="100px"
        height="15px"
      />
      <step-comment-modal :step-id="step.id">
        <template #activator="{show}">
          <b-button
            variant="flat-secondary"
            @click="show"
          >
            <feather-icon
              icon="MessageSquareIcon"
              class="mr-50"
            />
            Комментарий
          </b-button>
        </template>
      </step-comment-modal>
    </div>

    <div class="flex-fill" />

    <b-button
      v-if="!editable && !selectable"
      :variant="isFavorite ? 'flat-warning' : 'flat-secondary'"
      class="mr-50"
      @click="toggleFavorite"
    >
      <feather-icon
        class="mr-50"
        icon="BookmarkIcon"
        :class="isFavorite ? 'fill-warning' : ''"
      />
      <span v-if="isFavorite">В избранном</span>
      <span v-else>В избранное</span>
    </b-button>
    <b-button
      v-else-if="selectable"
      :variant="selected ? 'danger' : 'primary'"
      class="mr-50"
      @click="$emit('onSelect')"
    >
      <feather-icon
        v-if="selected"
        class="mr-50"
        icon="XIcon"
      />
      <feather-icon
        v-else
        class="mr-50"
        icon="PlusIcon"
      />
      <span v-if="selected">Убрать</span>
      <span v-else>Добавить</span>
    </b-button>

    <template v-if="editable">
      <b-button
        id="saving-button"
        :variant="editing ? 'flat-success' : 'flat-primary'"
        class="mr-1"
        :disabled="saving"
        @click="editing ? $emit('save') : $emit('edit')"
      >
        <div v-if="!saving">
          <feather-icon
            class="mr-50"
            :icon="editing ? 'CheckIcon' : 'EditIcon'"
          />
          <span v-if="!editing">Изменить</span>
          <span v-else>Сохранить</span>
        </div>
        <div
          v-else
          class="d-flex align-items-center"
        >
          <b-spinner small />
          <span class="ml-50">Сохранение...</span>
        </div>
      </b-button>

      <!--      <create-step-modal-->
      <!--        :step="step"-->
      <!--        :hometask-id="hometaskId"-->
      <!--      >-->
      <!--        <template #activator="{show}">-->
      <!--          <b-button-->
      <!--            variant="flat-info"-->
      <!--            class="mr-1"-->
      <!--            :disabled="saving"-->
      <!--            @click="show"-->
      <!--          >-->
      <!--            <div v-if="!saving">-->
      <!--              <feather-icon-->
      <!--                class="mr-50"-->
      <!--                icon="Edit2Icon"-->
      <!--              />-->
      <!--              <span>Переименовать</span>-->
      <!--            </div>-->
      <!--            <div-->
      <!--              v-else-->
      <!--              class="d-flex align-items-center"-->
      <!--            >-->
      <!--              <b-spinner small />-->
      <!--              <span class="ml-50">Сохранение...</span>-->
      <!--            </div>-->
      <!--          </b-button>-->
      <!--        </template>-->
      <!--      </create-step-modal>-->

      <b-button
        v-if="editable && !hometaskId"
        :disabled="editing"
        variant="flat-secondary"
        class="mr-1"
        @click.prevent="togglePrivate"
      >
        <feather-icon
          :icon="!step.private ? 'LockIcon' : 'UnlockIcon'"
          class="mr-50"
        />
        <span v-if="!step.private">Сделать личной</span>
        <span v-else>Сделать публичной</span>
      </b-button>

    </template>

    <b-button
      v-if="editing"
      variant="flat-secondary"
      class="mr-1"
      @click="$emit('preview')"
    >
      <feather-icon
        class="mr-50"
        :icon="!previewing ? 'EyeIcon' : 'EyeOffIcon'"
      />
      Предпросмотр
    </b-button>

    <b-button
      variant="flat-secondary"
      class="btn-icon"
      title="На весь экран"
      @click="$emit('fullscreen')"
    >
      <feather-icon
        size="18"
        :icon="fullscreen ? 'MinimizeIcon' : 'MaximizeIcon'"
      />
    </b-button>

    <b-dropdown
      size="sm"
      variant="flat-secondary"
      toggle-class="text-decoration-none btn-icon rounded-circle ml-1"
      no-caret
      boundary="viewport"
    >
      <template #button-content>
        <feather-icon
          size="18"
          icon="MoreVerticalIcon"
        />
      </template>
      <b-dropdown-item
        v-if="!hometaskId"
        :variant="isFavorite ? 'warning' : ''"
        @click="toggleFavorite"
      >
        <feather-icon
          class="mr-50"
          icon="BookmarkIcon"
          :class="isFavorite ? 'fill-warning' : ''"
        />
        <span v-if="isFavorite">В избранном</span>
        <span v-else>В избранное</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="editable"
        :variant="editing ? 'success' : 'primary'"
        @click="editing ? $emit('save') : $emit('edit')"
      >
        <feather-icon
          class="mr-50"
          :icon="editing ? 'CheckIcon' : 'EditIcon'"
        />
        <span v-if="!editing">Изменить</span>
        <span v-else>Сохранить</span>
      </b-dropdown-item>
      <create-step-modal
        v-if="editable"
        :step="step"
        :hometask-id="hometaskId"
      >
        <template #activator="{show}">
          <b-dropdown-item
            variant="info"
            :disabled="saving"
            @click="show"
          >
            <feather-icon
              class="mr-50"
              icon="Edit2Icon"
            />
            <span>Переименовать</span>
          </b-dropdown-item>
        </template>
      </create-step-modal>
      <b-dropdown-item
        v-if="editable && !hometaskId"
        :disabled="editing"
        @click.prevent="togglePrivate"
      >
        <feather-icon
          :icon="!step.private ? 'LockIcon' : 'UnlockIcon'"
          class="mr-50"
        />
        <span v-if="!step.private">Сделать личной</span>
        <span v-else>Сделать публичной</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="editable && !hometaskId && isAdmin"
        :disabled="editing"
        @click.prevent="copyStep"
      >
        <feather-icon
          icon="CopyIcon"
          size="14"
          class="mr-1"
        />
        Копировать
      </b-dropdown-item>
      <b-dropdown-divider v-if="editable" />
      <b-dropdown-item
        v-if="editable"
        variant="danger"
        @click="$emit('delete')"
      >
        <feather-icon
          class="mr-50"
          icon="XIcon"
        />
        <span>Удалить</span>
      </b-dropdown-item>
    </b-dropdown>

  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BDropdownDivider, BSpinner, BButton, BFormRating,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import CreateStepModal from '../CreateStepModal.vue'
import StepCommentModal from '../../../../../../steps/StepCommentModal.vue'
import { adminRole, userRoles } from '../../../../../../../store/user'
import { toast } from '../../../../../../../helpers'

export default {
  name: 'EditorHeader',
  components: {
    StepCommentModal,
    CreateStepModal,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormRating,
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    previewing: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    hometaskId: {
      type: String || Number,
      default: null,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    addingToHometask: false,
    userRating: {
      rating: null,
    },
    loadingRating: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    isFavorite() {
      return this.step.isFavorite
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isAdmin() {
      return this.userRole === adminRole
    },
  },
  watch: {
    'step.home_tasks_id': {
      handler() {
        this.addingToHometask = false
      },
    },
    step() {
      this.getRating()
    },
  },
  mounted() {
    this.getRating()
  },
  methods: {
    ...mapActions({
      addStepToFavorites: 'course/addStepToFavorites',
      removeStepFromFavorites: 'course/removeFromFavorites',
      copyStepRequest: 'course/copyStep',
    }),
    async toggleFavorite() {
      this.step.isFavorite = !this.step.isFavorite
      if (!this.step.isFavorite) {
        await this.removeStepFromFavorites(this.step.id)
      } else {
        await this.addStepToFavorites(this.step.id)
      }
    },
    async togglePrivate() {
      this.step.private = !this.step.private
      await this.$http.put(`/lesson-steps/update?id=${this.step.id}`, { ...this.step, private: this.step.private })
    },
    async copyStep() {
      const copy = await this.copyStepRequest(this.step.id)
      if (copy?.id) {
        toast({ title: 'Копия создана', text: 'Вы были перенаправлены на страницу копии', type: 'success' })
        this.$router.replace({ name: 'cabinet.step', params: { step_id: copy.id } })
      }
    },
    async getRating() {
      this.loadingRating = true
      this.userRating = {
        rating: null,
      }
      if (this.step.id) {
        const { items } = await this.$http.get('/step-ratings', {
          params: {
            user_id: this.user.id,
            lesson_step_id: this.step.id,
          },
        })
        if (items.length) this.userRating = { ...items[0] }
      }
      this.loadingRating = false
    },
    async saveRating(value) {
      const rating = value
      const data = {
        user_id: this.user.id,
        lesson_step_id: this.step.id,
        rating,
      }

      if (this.userRating.id) {
        const updatedUserRating = await this.$http.put(`/step-ratings/update?id=${this.userRating.id}`, { rating })
        this.userRating = { ...this.userRating, rating: updatedUserRating.rating }
      } else {
        const createdUserRating = await this.$http.post('/step-ratings/create', { ...data })
        this.userRating = { ...createdUserRating }
      }
    },
    goBack() {
      if (this.fullscreen) this.$emit('fullscreen')
      else if (this.isPreview) this.$emit('onClosePreview')
      else this.$router.go(-1)
    },
  },
}
</script>

<style scoped>

</style>
