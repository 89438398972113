<template>
  <div :class="wrapperClass">
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center flex-fill"
    >
      <b-spinner
        variant="primary"
        label="Spinning"
        style="width: 2rem; height: 2rem;"
      />
    </div>
    <b-img-lazy
      v-else-if="src && !avatar"
      :src="src"
      alt
      :class="imageClass"
      fluid
    />
    <b-avatar
      v-else-if="avatar"
      :src="src"
      alt
      :size="size"
      :class="imageClass"
    />
  </div>
</template>

<script>
import { BImgLazy, BAvatar, BSpinner } from 'bootstrap-vue'

const noImageImage = require('@/assets/images/no-image.jpg')

export default {
  name: 'StoreImage',
  components: {
    BImgLazy,
    BAvatar,
    BSpinner,
  },
  props: {
    storeId: {
      type: [String, Number],
      default: null,
    },
    imageClass: {
      type: [String, Object],
      default: null,
    },
    wrapperClass: {
      type: [String, Object],
      default: null,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Number],
      default: 38,
    },
    imageUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    src: null,
    loading: false,
  }),
  watch: {
    storeId: {
      handler() {
        if (this.storeId) {
          this.getImageUrl()
        }
      },
    },
  },
  mounted() {
    if (this.imageUrl) {
      this.src = this.imageUrl
    } else if (this.storeId) {
      this.getImageUrl()
    }
  },
  methods: {
    async getImageUrl() {
      this.loading = true
      try {
        const image = await this.$lazyHttp.storeGet(this.storeId)
        this.src = image.full_url
      } catch (e) {
        if (!this.avatar) this.src = noImageImage
      }
      this.loading = false
    },
  },
}
</script>
