<template>
  <b-card
    no-body
    class="d-flex full-height  "
    :class="isPreview ? '' : 'step-editor mb-0'"
  >
    <div
      v-if="!step"
      class="d-flex flex-column flex-fill align-items-center justify-content-center"
    >
      <h3>Этап урока не выбран</h3>
      <p>Выберите тему, чтобы просмотреть уроки</p>
    </div>

    <b-card
      v-else
      no-body
      class="flex-fill p-1"
      :class="{'card-fullscreen': fullscreen}"
    >
      <template v-if="!noHeader">
        <b-row class="mb-1">
          <b-col>
            <editor-header
              :step="step"
              :expandable="expandable"
              :editable="editable"
              :expanded="expanded"
              :fullscreen="fullscreen"
              :editing="editing"
              :saving="saving"
              :previewing="previewing"
              :hometask-id="hometaskId"
              :selectable="selectable"
              :is-preview="isPreview"
              :selected="selected"
              @preview="() => previewing = !previewing"
              @toggleExpand="$emit('toggleExpand')"
              @fullscreen="() => fullscreen = !fullscreen"
              @save="saveStep"
              @delete="deleteStep"
              @edit="() => editing = !editing"
              @addToHometask="$emit('addToHometask')"
              @onClosePreview="$emit('onClosePreview')"
              @onSelect="$emit('onSelect')"
            />
          </b-col>
        </b-row>
        <div class="thin-divider mb-1" />
      </template>
      <b-row>
        <b-col
          v-if="elements.length"
          cols
        >
          <b-card
            :no-body="!!step.lesson_id"
            class="p-1"
          >
            <b-card-title v-if="!step.lesson_id && editingOrPreviewing">
              Содержимое карточки
            </b-card-title>

            <draggable
              v-model="elements"
              tag="div"
              group="stepElements"
              class="list-group list-group-flush"
              :class="{'ml-1': editing && !previewing && step.lesson_id}"
              handle=".drag-handle"
              @end="updateOrder"
            >
              <div
                v-for="(element, index) in elements"
                :key="index"
              >
                <step-elements
                  :editing-or-previewing="editingOrPreviewing"
                  :element="element"
                  :exercises="step.exercises"
                  :index="index"
                  :image-as-board="imagesAsBoard"
                  :hometask-id="hometaskId"
                  @delete="(ind) => deleteElement(ind)"
                />
              </div>
            </draggable>
          </b-card>
        </b-col>
        <b-col
          v-if="editing && !previewing"
          :cols="!elements.length ? 12 : 'auto'"
        >
          <step-tools-panel
            :step="step"
            :centered="!elements.length"
            @addElement="addElement"
            @onUpdate="$emit('onUpdate')"
          />
        </b-col>
      </b-row>
    </b-card>

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Вы уверены, что хотите удалить этап?`"
    />
  </b-card>
</template>

<script>
import draggable from 'vuedraggable'
import {
  BCard, BCardTitle, BRow, BCol,
} from 'bootstrap-vue'
import * as _ from 'lodash'
import StepToolsPanel from '@/components/new_courses/course/theme/lesson/step/components/StepToolsPanel.vue'
import EditorHeader from '@/components/new_courses/course/theme/lesson/step/components/EditorHeader.vue'
import ConfirmModal from '@/components/page-elements/modals/ConfirmModal.vue'
import StepElements from '@/components/new_courses/course/theme/lesson/step/components/StepElements.vue'

export default {
  name: 'StepEditor',
  components: {
    StepElements,
    ConfirmModal,
    EditorHeader,
    StepToolsPanel,
    draggable,
    BRow,
    BCol,
    BCard,
    BCardTitle,
  },
  props: {
    step: {
      type: Object,
      default: null,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    hometaskId: {
      type: Number,
      default: null,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    imagesAsBoard: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fullscreen: false,
    editing: false,
    deletedElements: [],
    saving: false,
    previewing: false,
  }),
  computed: {
    apiElementsUrl() {
      if (this.step.home_tasks_id) return '/home-tasks-step-elements/'
      return '/step-elements'
    },
    apiStepUrl() {
      if (this.step.home_tasks_id) return '/home-tasks-steps/'
      return '/lesson-steps'
    },
    elements: {
      get() {
        if (!this.step) return []
        if (this.step.home_tasks_id) return this.step.homeTasksStepElements
        return this.step.stepElements
      },
      set(value) {
        if (this.step.home_tasks_id) this.step.homeTasksStepElements = value
        else this.step.stepElements = value
      },
    },
    editingOrPreviewing() {
      return !this.previewing && this.editing
    },
  },
  watch: {
    step() {
      if (!this.editable) return
      if (this.step && !this.elements.length) this.editing = true
      else this.editing = false
    },
    editing(newValue) {
      if (newValue && !this.expanded) {
        this.$emit('toggleExpand')
      } else if (this.expanded) {
        this.$emit('toggleExpand')
      }
    },
  },
  mounted() {
    if (this.edit && this.editable) this.editing = true
  },
  methods: {
    updateOrder() {
      this.elements = this.elements.map((el, index) => ({ ...el, order: index }))
    },
    addElement(type) {
      const element = {
        type,
        order: this.elements.length,
        data: null,
        data_version: 1,
        disabled: 0,
        step_id: this.step.id,
        file: null,
      }
      this.elements.push(element)
    },
    async saveStep() {
      const { apiElementsUrl } = this
      this.saving = true

      await Promise.all(this.deletedElements.map(async element => {
        if (element.id) {
          await this.$http.delete(`${apiElementsUrl}/delete?id=${element.id}`)
        }
        return element
      }))
      this.deletedElements = []

      this.elements = await Promise.all(this.elements.map(async element => {
        const elementObj = { ...element }
        const fileElements = ['image', 'audio', 'doc']

        if (!_.isNil(elementObj.data)) elementObj.data = JSON.stringify(elementObj.data)

        if (fileElements.includes(elementObj.type)) {
          if (elementObj.file instanceof File) {
            const { id } = await this.$http.storeCreate(elementObj.file)
            elementObj.data = JSON.stringify(id)
            elementObj.file = null
          }
        }

        elementObj.disabled = elementObj.disabled === true ? 1 : 0
        if (this.step.home_tasks_id) elementObj.home_tasks_steps_id = this.step.id

        const config = { params: { expand: 'image' } }

        if (elementObj.id) {
          return this.$http.put(`${apiElementsUrl}/update?id=${elementObj.id}`, elementObj, config)
        }
        return this.$http.post(`${apiElementsUrl}/create`, elementObj, config)
      }))
      this.editing = false
      this.fullscreen = false
      this.saving = false
      if (!this.hometaskId) {
        this.$eventBus.$emit('page-title', this.step.name)
      }
    },
    deleteStep() {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$http.delete(`${this.apiStepUrl}/delete?id=${this.step.id}`)
              .then(() => {
                this.$emit('deleted', this.step)
              })
          }
        })
    },
    deleteElement(index) {
      this.deletedElements.push(this.elements[index])
      this.elements.splice(index, 1)
    },
  },
}
</script>

<style scoped lang="scss">
.drag-handle {
  cursor: grab;
}
.step-editor {
  min-height: calc(100vh - 155px);
}
</style>
