<template>
  <div v-if="test">
    <p class="font-medium-1">
      {{ $t(`page.course.test.question`) }}
    </p>
    <b-form-group>
      <b-form-input
        id="step-test-text"
        v-model="test.text"
        :placeholder="$t(`page.course.test.enter_question`)"
      />
    </b-form-group>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <span class="font-medium-1">{{ $t(`page.course.test.answers`) }}</span>
      <b-button
        type="button"
        variant="outline-secondary"
        size="sm"
        @click="addAnswer"
      >
        <feather-icon
          icon="PlusIcon"
          size="15"
          class="mr-1"
        />
        <span>{{ $t(`page.course.test.add_answer`) }}</span>
      </b-button>
    </div>
    <div
      v-for="(answer, index) in test.answers"
      :key="index"
    >
      <lesson-test-answer
        :answer="answer"
        :index="index"
        :deletable="test.answers.length > 1"
        @delete="test.answers.splice(index, 1)"
      />
    </div>
    <b-form-group
      class="mt-1"
      label-for="step-test-multiply"
    >
      <div class="d-flex flex-row justify-content-between align-items-center">
        <span
          v-if="test.canMultiplyCorrect"
          class="text-warning"
        >
          {{ $t(`page.course.test.multiply`) }}
        </span>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import LessonTestAnswer from '@/components/new_courses/course/theme/lesson/LessonTestAnswer.vue'

export default {
  name: 'LessonTest',
  components: {
    LessonTestAnswer,
    BFormGroup,
    BFormInput,
    BButton,
  },
  props: {
    testData: {
      type: Object,
      default: () => ({
        text: '',
        canMultiplyCorrect: false,
        answers: [],
      }),
    },
  },
  data: () => ({
    test: null,
  }),
  computed: {
    defaultTestData() {
      return {
        text: '',
        canMultiplyCorrect: false,
        answers: [],
      }
    },
    hasMultipleCorrectAnswers() {
      return this.test.answers.filter(answer => answer.isCorrect).length > 1
    },
  },
  watch: {
    test: {
      handler() {
        this.test.canMultiplyCorrect = this.hasMultipleCorrectAnswers
        this.$emit('change', this.test)
      },
      deep: true,
    },
    testData() {
      this.test = { ...this.testData }
    },
  },
  mounted() {
    this.test = this.testData ? { ...this.testData } : { ...this.defaultTestData }
    if (!this.test.answers.length) this.addAnswer()
  },
  methods: {
    addAnswer() {
      this.test.answers.push({
        text: '',
        image_id: null,
        isCorrect: false,
      })
    },
    canMultiplyCorrectChange(value) {
      if (!value) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.test.answers.length; i++) {
          this.test.answers[i].isCorrect = false
        }
      }
    },
    setTest(test) {
      this.test = { ...test }
    },
  },
}
</script>
