<template>
  <div>
    <div
      v-if="canBeMinimized"
      class="d-flex justify-content-end"
    >
      <template v-if="!asHometask">
        <b-button
          class="mr-50"
          size="sm"
          variant="flat-primary"
          @click="sendImageToBigBoard()"
        >
          <feather-icon
            class="mr-50"
            icon="ArrowLeftIcon"
          />
          Фон на доску
        </b-button>
        <b-button
          size="sm"
          variant="flat-primary"
          @click="sendImageToBigBoard(canvasData)"
        >
          Фон + метки
        </b-button>
        <div class="flex-fill" />
      </template>
      <b-button
        size="sm"
        variant="flat-secondary"
        @click="() => minimize(!expanded)"
      >
        <feather-icon
          :icon="!expanded ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          class="mr-50"
        />
        {{ !expanded ? 'Показать доску' : 'Свернуть доску' }}
      </b-button>
    </div>
    <b-collapse
      v-model="expanded"
      role="board"
    >
      <div
        id="small-board"
        ref="smallBoard"
        :key="updateBoard"
        class="mx-auto"
        :style="{height: `${scaledHeight + (isMobile ? 100 : 150)}px`, 'max-width': '600px'}"
        @mouseleave="saveBoard"
      />
    </b-collapse>
  </div>
</template>

<script>
import { BButton, BCollapse } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { dataURLtoFile } from '@core/utils/utils'
import { format, getUnixTime } from 'date-fns'
import { $themeBreakpoints } from '@themeConfig'
import { teacherRole, userRoles } from '@/store/user'

export default {
  name: 'StepSmallBoard',
  components: {
    BButton,
    BCollapse,
  },
  props: {
    imageId: {
      type: Number,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    asHometask: {
      type: Boolean,
      default: false,
    },
    sessionId: {
      type: Number,
      default: null,
    },
    hometaskId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    scaledHeight: 0,
    designer: null,
    updateBoard: 0,
    canvasData: {},
    expanded: true,
    boardUnsaved: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    canBeMinimized() {
      return ['cabinet.sessions.online', 'cabinet.room'].includes(this.$route.name) && this.isTeacher
    },
    isMobile() {
      return window.innerWidth <= $themeBreakpoints.md
    },
  },
  watch: {
    imageUrl() {
      this.initDesigner()
    },
  },
  destroyed() {
    this.$videoStreamEventBus.$off('sync-own-small-board')
    this.$videoStreamEventBus.$off('sync-own-small-board-collapse')
    window.removeEventListener('resize', () => {})
  },
  mounted() {
    this.initDesigner()
    this.$videoStreamEventBus.$on('sync-own-small-board', data => {
      this.designer.syncData(data)
    })
    window.addEventListener('resize', () => {
      this.scaledHeight = this.getScaledImageHeight()
    })
    this.$videoStreamEventBus.$on('sync-own-small-board-collapse', data => {
      this.expanded = data
    })
  },
  methods: {
    ...mapActions({
      saveBoardToServer: 'videoStream/saveBoard',
      addFileToSessionStore: 'videoStream/addFileToSessionStore',
      addFileToHometaskStore: 'hometask/addFileToHometaskStore',
    }),
    async initDesigner() {
      this.updateBoard += 1

      // eslint-disable-next-line no-undef
      this.designer = new CanvasDesigner()

      this.designer.setToolsPosition('bottom')

      this.designer.widgetHtmlURL = '/canvas_designer/widget.html'// you can place this file anywhere
      this.designer.widgetJsURL = '/canvas_designer/widget.js' // you can place this file anywhere

      this.designer.addSyncListener(data => {
        this.saveCanvasData(data)
        this.boardUnsaved = true
        this.$videoStreamEventBus.$emit('sync-foreign-small-board', data)
      })

      this.designer.setSelected('pencil')
      this.designer.setTools({
        pencil: true,
        marker: true,
        pointer: !this.asHometask,
        lineWidth: true,
        colorsPicker: true,
        undo: this.isTeacher,
        clear: this.isTeacher || this.asHometask,
      })

      this.$nextTick(() => {
        this.designer.appendTo(document.getElementById('small-board'), this.sendImageToSmallBoard)
        this.scaledHeight = this.getScaledImageHeight()
      })
    },
    sendImageToBigBoard(data = {}) {
      this.$videoStreamEventBus.$emit('set-big-board-background-image', { image: this.imageUrl, data })
    },
    sendImageToSmallBoard() {
      this.designer.setBackgroundImage(this.imageUrl, true)
    },
    getScaledImageHeight() {
      const cont = document.getElementById('small-board')
      if (cont) {
        const boardContainerWidth = cont.scrollWidth

        const ratX = 16
        const ratY = 10

        const ratio = boardContainerWidth / ratX

        return ratio * ratY
      }
      return 0
    },
    saveCanvasData(data) {
      const { points, startIndex } = data
      if (points) {
        if (!this.canvasData.points) this.canvasData.points = []
        if (startIndex === 0) {
          this.canvasData.points = points
        } else {
          this.canvasData.points = [...this.canvasData.points, ...points]
        }
        if (this.canvasData.startIndex === undefined) this.canvasData.startIndex = 0
      }
    },
    minimize(state) {
      this.expanded = state
      this.$videoStreamEventBus.$emit('sync-small-board-collapse', state)
    },
    async saveBoard() {
      if (!this.boardUnsaved) return
      this.boardUnsaved = false

      const iframe = document.getElementById('small-board').children[0]
      const canvas = iframe.contentWindow.document.getElementById('main-canvas')
      const image = canvas.toDataURL()
      if (this.sessionId) {
        const file = dataURLtoFile(image, `session-${this.sessionId}-board-${Date.now()}`)
        await this.addFileToSessionStore({ sessionId: this.sessionId, type: 'board', file })

        const name = format(Date.now(), 'dd.MM.yyyy HH:mm')
        const id = getUnixTime(Date.now())
        await this.saveBoardToServer({
          id, name, data: this.canvasData, background: this.canvasBackground, preview: image,
        })
        this.$videoStreamEventBus.$emit('board-saved')
      } else if (this.hometaskId) {
        const filename = `hometask-${this.hometaskId}-image-${this.imageId}-board`
        const file = dataURLtoFile(image, filename)
        await this.addFileToHometaskStore({
          hometaskId: this.hometaskId, file, image_id: this.imageId,
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
