<template>
  <div class="mb-1">
    <lesson-test
      v-if="editing"
      :test-data="testData"
      @change="tData => element.data = tData"
    />
    <preview-step-test
      v-else-if="testData"
      :test-data="testData"
    />
  </div>
</template>

<script>
import LessonTest from '@/components/new_courses/course/theme/lesson/LessonTest.vue'
import PreviewStepTest from '@/components/video-stream/actions/StepTest.vue'

export default {
  name: 'StepTest',
  components: { LessonTest, PreviewStepTest },
  props: {
    element: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    defaultTest: {
      text: '',
      canMultiplyCorrect: false,
      answers: [],
    },
    testData: null,
  }),
  watch: {
    element() {
      this.setTestData()
    },
  },
  mounted() {
    this.setTestData()
  },
  methods: {
    setTestData() {
      if (!this.element.data) this.testData = this.defaultTest
      else {
        try {
          this.testData = JSON.parse(this.element.data)
          // eslint-disable-next-line no-empty
        } catch (err) {
          this.testData = this.element.data
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
