<template>
  <div
    v-if="currentExercise"
    class="d-flex flex-column justify-content-between h-100"
    style="max-width: 500px; margin-inline: auto"
  >
    <div class="p-1">
      <exercise-mode-selector
        :exercises-mode="exercisesMode"
        :disabled="isFinished"
        :exercise-mode-changed="setExercisesMode"
      />

      <div v-if="!isFinished">
        <h1 class="font-medium-5 text-center mt-2">
          {{ currentExercise.name }}
        </h1>
        <h3 class="font-medium-1 text-center">
          {{ currentExercise.question }}
        </h3>
        <div class="pt-2">
          <store-image
            v-if="currentExercise.image_id"
            :store-id="currentExercise.image_id"
          />
          <h3 class="text-primary font-weight-bolder text-center mb-2">
            {{ currentExerciseData.exercise }}
          </h3>
          <exercise-answers
            :exercise-answers="currentExerciseData.answers"
            :answer-selected="answerSelected"
            :answer-removed="answerRemoved"
            :selected-answers="currentExerciseSelectedAnswers"
            :answered="currentExerciseAnswered"
            :type="currentExercise.type"
          />
        </div>
        <div v-if="currentExerciseAnswered">
          <b-alert
            show
            :variant="currentAnswerIsCorrect ? 'success' : 'danger'"
            class="p-2"
          >
            <div class="text-center font-medium-2">
              <span v-if="currentAnswerIsCorrect">{{ $t('page.video-stream.stream-exercises.labels.correct') }}!</span>
              <span v-else>{{ $t('page.video-stream.stream-exercises.labels.incorrect') }} :(</span>
            </div>
          </b-alert>
          <b-button
            v-if="exercisesMode !== 'testing'"
            block
            variant="outline-primary"
            :disabled="!currentExerciseSelectedAnswers"
            @click="nextExercise()"
          >
            {{ $t('page.video-stream.stream-exercises.labels.next_exercise') }}
          </b-button>
        </div>
        <div v-else-if="isMultiple">
          <b-button
            block
            class="shadow-none"
            variant="primary"
            :disabled="!currentExerciseSelectedAnswers.length"
            @click="answerQuestion()"
          >
            {{ $t('page.video-stream.stream-exercises.labels.confirm_selected') }}
          </b-button>
        </div>
      </div>
      <exercise-finished
        v-else
        :total="exercises.length"
        :correct="totalCorrect"
      />
    </div>
    <div>
      <div
        v-if="!inStep"
        class="thin-divider"
      />
      <div class="d-flex align-items-center">
        <div
          v-if="isTeacher && !inStep"
          class="p-50 d-flex align-items-center step-modes-container"
        >
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            title="Вернуться к теории"
            @click="$videoStreamEventBus.$emit('close-exercise')"
          >
            <feather-icon
              size="18"
              icon="BookIcon"
            />
          </b-button>
        </div>
        <exercise-info
          :exercises-number="exercises.length"
          :current-index="currentExerciseIndex"
          :total-correct="totalCorrect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BAlert,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ExerciseAnswers from '@/components/video-stream/actions/exercise/ExerciseAnswers.vue'
import ExerciseFinished from '@/components/video-stream/actions/exercise/ExerciseFinished.vue'
import ExerciseModeSelector from '@/components/video-stream/actions/exercise/ExerciseModeSelector.vue'
import ExerciseInfo from '@/components/video-stream/actions/exercise/ExerciseInfo.vue'
import StoreImage from '@/components/store/StoreImage.vue'
import { teacherRole, userRoles } from '@/store/user'

export default {
  name: 'StreamExerciseQuestions',
  components: {
    ExerciseModeSelector,
    ExerciseInfo,
    ExerciseFinished,
    ExerciseAnswers,
    BButton,
    BAlert,
    StoreImage,
  },
  props: {
    hometaskId: {
      type: Number,
      default: null,
    },
    exerciseQuestions: {
      type: Array,
      default: () => [],
    },
    inStep: {
      type: Boolean,
      default: false,
    },
    shouldSaveResult: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    exercises: [],
    exercisesMode: 'training',
    currentExercise: null,
    currentExerciseIndex: 0,
    currentExerciseSelectedAnswers: [],
    currentExerciseAnswered: false,
    totalCorrect: 0,
    totalExercisesNumber: 0,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      exercisesFromStorage: 'videoStream/get_exercises',
      session: 'videoStream/get_session',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    currentExerciseData() {
      const { data } = this.currentExercise
      try {
        return JSON.parse(data).questions
      } catch (e) {
        return data.questions
      }
    },
    currentAnswerIsCorrect() {
      const correctAnswers = this.currentExerciseData.answers.filter(answer => answer.isCorrect)
      const correctSelectedAnswers = this.currentExerciseSelectedAnswers.filter(answer => answer.isCorrect)
      return correctAnswers.length === correctSelectedAnswers.length && this.currentExerciseSelectedAnswers.length === correctAnswers.length
    },
    isTestingMode() {
      return this.exercisesMode === 'testing'
    },
    isFinished() {
      return this.currentExerciseIndex === this.exercises.length
    },
    isMultiple() {
      return this.currentExerciseData.hasMultipleAnswers
    },
  },
  watch: {
    exerciseQuestions() {
      this.setExercises()
    },
  },
  beforeMount() {
    this.setExercises()
  },
  mounted() {
    this.updateCurrentExercise()
    this.$videoStreamEventBus.$on('lesson-exercises-answer-selected', data => {
      if (data.senderId === this.user.id) return
      this.currentExerciseSelectedAnswers.push(data.answer)
    })
    this.$videoStreamEventBus.$on('lesson-exercises-answer-removed', data => {
      if (data.senderId === this.user.id) return
      this.currentExerciseSelectedAnswers.splice(this.currentExerciseSelectedAnswers.indexOf(data.answer), 1)
    })
    this.$videoStreamEventBus.$on('lesson-exercises-answered', data => {
      if (data.senderId === this.user.id) return
      this.currentExerciseAnswered = true
    })
    this.$videoStreamEventBus.$on('lesson-exercises-next', data => {
      if (data.senderId === this.user.id) return
      this.nextExercise(true)
    })
    this.$videoStreamEventBus.$on('lesson-exercises-mode-switched', mode => {
      this.exercisesMode = mode
    })
    this.$videoStreamEventBus.$on('lesson-exercises-set-params', params => {
      this.exercisesMode = params.exercisesMode
      this.updateCurrentExercise()
      this.currentExerciseSelectedAnswers = params.currentExerciseSelectedAnswers
      this.currentExerciseAnswered = params.currentExerciseAnswered
      this.totalCorrect = params.totalCorrect
    })
  },
  methods: {
    setExercises() {
      if (this.exerciseQuestions.length) this.exercises = this.exerciseQuestions
      else this.exercises = this.exercisesFromStorage
    },
    setExercisesMode(mode) {
      this.exercisesMode = mode
      this.$videoStreamEventBus.$emit('stream-exercise-mode-switched', mode)
    },
    updateCurrentExercise() {
      this.currentExercise = { ...this.exercises[this.currentExerciseIndex] }
    },
    answerSelected(answer) {
      if (this.isTestingMode && this.isTeacher) return
      if (this.currentExerciseSelectedAnswers.length === 1 && !this.isMultiple) return
      this.currentExerciseSelectedAnswers.push(answer)
      this.$videoStreamEventBus.$emit('stream-exercise-answer-selected', answer)
      if (!this.isMultiple) {
        this.answerQuestion()
      }
    },
    answerRemoved(answer) {
      if (this.isTestingMode && this.isTeacher) return
      this.currentExerciseSelectedAnswers.splice(this.currentExerciseSelectedAnswers.indexOf(answer), 1)
      this.$videoStreamEventBus.$emit('stream-exercise-answer-removed', answer)
    },
    answerQuestion() {
      this.currentExerciseAnswered = true
      this.$videoStreamEventBus.$emit('stream-exercise-answered')
      if (this.isTestingMode) {
        setTimeout(() => {
          this.nextExercise()
        }, 3 * 1000)
      }
    },
    nextExercise(fromWebSocket = false) {
      if (!this.isTeacher) {
        this.saveResult()
      }
      if (this.currentAnswerIsCorrect) this.totalCorrect += 1
      this.currentExerciseAnswered = false
      this.currentExerciseSelectedAnswers = []
      this.currentExerciseIndex += 1

      if (this.currentExerciseIndex <= this.exercises.length) {
        this.currentExercise = { ...this.exercises[this.currentExerciseIndex] }
        if (!fromWebSocket) this.$videoStreamEventBus.$emit('stream-exercise-next', { index: this.currentExerciseIndex, totalCorrect: this.totalCorrect })
      }
    },
    async saveResult() {
      if (this.shouldSaveResult) {
        const data = {
          user_id: this.user.id,
          correct: +this.currentAnswerIsCorrect,
          answers: JSON.stringify(this.currentExerciseSelectedAnswers),
          exercise_id: this.currentExercise.exercises_id,
        }
        if (this.hometaskId) {
          data.home_task_id = this.hometaskId
          await this.$http.post('/home-task-exercise-answers/create', data)
        } else {
          data.session_id = this.session?.id
          await this.$http.post('/lesson-session-exercise-answers/create', data)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
