<template>
  <div
    class="card-img-top"
    :style="image"
    :class="{'no-image': !imageId}"
  />
</template>

<script>
export default {
  name: 'CourseImage',
  props: {
    imageId: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: String,
      default: '240px',
    },
    fullUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    url: null,
    image: {
      height: '240px',
      width: '100%',
      backgroundImage: null,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
  }),
  computed: {
    cssUrl() {
      if (this.url) return `url(${this.url})`
      return null
    },
  },
  watch: {
    imageId() {
      this.loadImage()
    },
    url() {
      this.image.backgroundImage = this.cssUrl
    },
  },
  async created() {
    this.image.height = this.imageHeight
    if (!this.imageId) {
      this.url = this.fullUrl
    } else {
      await this.loadImage()
    }
  },
  methods: {
    async loadImage() {
      try {
        const { url } = await this.$lazyHttp.storeGet(this.imageId)
        this.url = `${process.env.VUE_APP_API_URL}${url}`
      } catch (e) {
        // console.error(`Error when loading course image ${this.imageId}`)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.card-img-top {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-top-right-radius: 0;

  &.no-image {
    background-image: url('~@/assets/images/course.jpg');
  }
}
</style>
