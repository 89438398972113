<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      :title="title"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template #default="{ hide }">
        <!-- form -->
        <b-form @submit.prevent="saveStep">

          <div class="p-2">
            <b-form-group
              label-for="name"
              label="Название"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                v-validate="'required'"
                required
                placeholder="Введите название"
                autofocus
                name="name"
                :state="errors.first('name') ? false : null"
              />
              <small class="text-danger">{{ errors.first('name') }}</small>
            </b-form-group>
            <b-row v-if="!course">
              <b-col
                cols="12"
              >
                <b-form-group
                  label-for="subject_id"
                  label="Предмет"
                >
                  <v-select
                    id="subject_id"
                    v-model="form.subject_id"
                    :options="subjects"
                    label="text"
                    name="subject_id"
                    :reduce="s => s.id"
                    placeholder="Выберите предмет"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group
                  label-for="grade_id"
                  label="Класс"
                >
                  <v-select
                    id="grade_id"
                    v-model="form.grade_id"
                    :options="grades"
                    label="name"
                    name="grade_id"
                    :reduce="s => s.id"
                    placeholder="Выберите класс"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              v-if="!hometaskId"
              label-for="autocomplete"
              label="Теги"
            >
              <tags-autocomplete
                pill
                no-shadow
                :tags="form.lessonStepsTags"
                @selected="(selected) => form.lessonStepsTags = selected"
              />
            </b-form-group>

            <b-form-radio-group
              v-if="!form.id || form.user_id"
              v-model="form.private"
              class="mt-1"
              name="private"
            >
              <b-form-radio
                :value="false"
              >
                <p class="mb-0">
                  Публичная
                </p>
                <span class="text-muted font-small-3">Доступна для просмотра и использования другими пользователями, но редактировать или удалять можете только Вы</span>
              </b-form-radio>
              <b-form-radio
                class="mt-50"
                :value="true"
              >
                <p class="mb-0">
                  Личная
                </p>
                <span class="text-muted font-small-3">Недоступна другим пользователям</span>
              </b-form-radio>
            </b-form-radio-group>
          </div>

          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
            >
              <b-spinner
                v-if="saving"
                small
                class="mr-50"
              />
              <span v-if="saving">Сохранение</span>
              <span v-else-if="!form.id">Создать</span>
              <span v-else>Сохранить</span>
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="hide"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BModal, BFormRadioGroup, BFormRadio, BSpinner, BCol, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import TagsAutocomplete from '../../../../../page-elements/filters/TagsAutocomplete.vue'

export default {
  name: 'CreateStepModal',
  components: {
    TagsAutocomplete,
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BSpinner,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    course: {
      type: Object,
      default: null,
    },
    lesson: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    hometaskId: {
      type: String || Number,
      default: null,
    },
  },
  data: () => ({
    form: {
      name: null,
      type: 1,
      grade_id: null,
      subject_id: null,
      private: false,
      lessonStepsTags: [],
    },
    display: false,
    title: 'Создание карточки',
    saving: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      subjects: 'course/get_subjects',
      grades: 'course/get_grades',
    }),
    dict() {
      return {
        custom: {
          name: {
            required: 'Название карточки обязательно',
          },
        },
      }
    },
    apiStepUrl() {
      if (this.step?.home_tasks_id) return '/home-tasks-steps'
      return '/lesson-steps'
    },
  },
  watch: {
    display() {
      if (!this.step) {
        this.form = {
          name: null,
          type: 1,
          grade_id: this.course?.grade_id ?? null,
          subject_id: this.course?.subjects_id ?? null,
          private: false,
          lessonStepsTags: [],
        }
      } else {
        this.form = { ...this.step }
      }
    },
  },
  mounted() {
    if (this.step) {
      this.form = { ...this.step }
      this.title = 'Обновление карточки'
    }

    this.$validator.localize('ru', this.dict)
    if (!this.subjects.length) this.getSubjects()
    if (!this.grades.length) this.getGrades()
  },
  methods: {
    ...mapActions({
      getSubjects: 'course/getSubjects',
      getGrades: 'course/getGrades',
    }),
    async saveStep() {
      this.saving = true

      await this.$validator.validate().then(async valid => {
        if (valid) {
          if (this.step) await this.updateStep()
          else await this.createStep()
        }
      })

      this.$eventBus.$emit('page-title', this.form.name)
    },
    async createStep() {
      const { apiStepUrl, form } = this

      const {
        name, type, subject_id, grade_id, private: privateMark, lessonStepsTags,
      } = form

      await this.$http.post(`${apiStepUrl}/create?expand=user,stepElements.image,lessonStepsTags.tags`, {
        ...{
          name, type, subject_id, grade_id, private: privateMark, is_template: 0,
        },
        user_id: this.user.id,
        lesson_id: this.lesson?.id,
      })
        .then(async response => {
          this.form = { ...response, lessonStepsTags }
          if (!this.hometaskId) await this.saveTags()
          this.$emit('created', response)
          this.display = false
        })
      this.saving = false
    },
    async updateStep() {
      const { apiStepUrl, form } = this

      const {
        name, type, subject_id, grade_id, private: privateMark, lessonStepsTags,
      } = form

      await this.$http.put(`${apiStepUrl}/update?id=${this.step.id}&expand=user,stepElements.image,lessonStepsTags.tags`, {
        name, type, subject_id, grade_id, private: privateMark, is_template: 0,
      })
        .then(async response => {
          this.form = { ...response, lessonStepsTags }
          if (!this.hometaskId) await this.saveTags()
          this.$emit('updated', response)
          this.display = false
        })
      this.saving = false
    },
    async saveTags() {
      this.form.lessonStepsTags = await Promise.all(this.form.lessonStepsTags.map(async stepTag => {
        const tag = { ...stepTag }
        if (!tag.tags_id) {
          const { id } = await this.$http.post('/tags/create', { ...tag.tags })
          tag.tags_id = id
        }
        if (!tag.id) {
          tag.lesson_steps_id = this.form.id
          tag.user_id = this.form.user_id
          const { id } = await this.$http.post('/lesson-steps-tags/create', tag)
          tag.id = id
        }

        return tag
      }))
    },
    show() {
      this.display = true
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
