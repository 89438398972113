<template>
  <div class="pt-2 pb-1">
    <p class="mb-0  text-primary font-small-3">
      {{ $t('page.video-stream.stream-actions.labels.test') }}
    </p>

    <h4 class="mb-2">
      {{ testData.text }}
    </h4>

    <exercise-answers
      :exercise-answers="testData.answers"
      :answer-selected="answerSelected"
      :answer-removed="answerRemoved"
      :selected-answers="selectedAnswers"
      :answered="answered"
      :type="2"
    />
    <div v-if="answered">
      <b-alert
        show
        :variant="currentAnswerIsCorrect ? 'success' : 'danger'"
        class="p-2"
      >
        <div class="text-center font-medium-2">
          <span v-if="currentAnswerIsCorrect">{{ $t('page.video-stream.stream-exercises.labels.correct') }}!</span>
          <span v-else>{{ $t('page.video-stream.stream-exercises.labels.incorrect') }} :(</span>
        </div>
      </b-alert>
    </div>
    <div v-else>
      <b-button
        block
        class="shadow-none"
        variant="primary"
        :disabled="!selectedAnswers.length"
        @click="answerQuestion()"
      >
        {{ $t('page.video-stream.stream-exercises.labels.confirm_selected') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BAlert, BButton } from 'bootstrap-vue'
import ExerciseAnswers from '@/components/video-stream/actions/exercise/ExerciseAnswers.vue'

export default {
  name: 'StepTest',
  components: {
    ExerciseAnswers,
    BAlert,
    BButton,
  },
  props: {
    testData: {
      type: Object,
      required: true,
    },
    canAnswer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedAnswers: [],
    answered: false,
  }),
  computed: {
    isMultiple() {
      return this.testData.canMultiplyCorrect
    },
    currentAnswerIsCorrect() {
      const correctAnswers = this.testData.answers.filter(answer => answer.isCorrect)
      const correctSelectedAnswers = this.selectedAnswers.filter(answer => answer.isCorrect)
      return correctAnswers.length === correctSelectedAnswers.length && this.selectedAnswers.length === correctAnswers.length
    },
  },
  mounted() {
  },
  methods: {
    answerSelected(answer) {
      if (this.selectedAnswers.length === 1 && !this.isMultiple) return
      this.selectedAnswers.push(answer)
      // this.$videoStreamEventBus.$emit('socket-lesson-exercises-answer-selected', answer)
    },
    answerRemoved(answer) {
      this.selectedAnswers.splice(this.selectedAnswers.indexOf(answer), 1)
      // this.$videoStreamEventBus.$emit('socket-lesson-exercises-answer-removed', answer)
    },
    answerQuestion() {
      this.answered = true
      // this.$videoStreamEventBus.$emit('socket-lesson-exercises-answered')
    },
  },
}
</script>
