<template>
  <div class="mb-1">
    <b-form-input
      v-if="editing"
      v-model="element.data"
      :placeholder="$t(`page.course.lesson-step.labels.${element.type}`)"
    />
    <h3 v-else-if="!element.disabled">
      {{ element.data }}
    </h3>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'

export default {
  name: 'StepTitle',
  components: {
    BFormInput,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    element() {
      this.setData()
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      try {
        this.element.data = JSON.parse(this.element.data)
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
  },
}
</script>

<style scoped>

</style>
