<template>
  <router-link
    :target="external ? '_blank' : ''"
    :to="{ name: 'cabinet.course', params: { course_id: course.id } }"
  >
    <b-card
      img-alt="course card"
      class="mb-1"
      no-body
    >
      <b-row
        no-gutters
        class="full-height "
      >
        <b-col
          cols="3"
          class="cursor-pointer"
          @mouseover="isMouseOver = true"
          @mouseleave="isMouseOver = false"
        >
          <b-overlay
            show
            spinner-variant="primary"
            spinner-type="grow"
            rounded="sm"
            class="zindex-0"
            no-wrap
            bg-color="transparent"
          />
          <course-image
            class="zindex-2 position-relative"
            image-height="100%"
            :full-url="course.image ? course.image.full_url : null"
          />
          <b-overlay
            :show="isMouseOver"
            class="zindex-3"
            no-wrap
          >
            <template #overlay>
              <div class="d-flex flex-column justify-content-center align-items-center text-primary">
                <feather-icon
                  size="22"
                  class="mb-50"
                  icon="ExternalLinkIcon"
                />
                <span class="font-weight-bold text-uppercase font-medium-1">Подробнее</span>
              </div>
            </template>
          </b-overlay>

        </b-col>
        <b-col>
          <b-card-body
            style="min-height: 150px"
            class="d-flex flex-column full-height"
          >
            <div class="d-flex align-items-start">
              <div>
                <b-card-title class="mb-50">
                  {{ course.name }}
                </b-card-title>
                <b-card-text>
                  {{ course.description }}
                </b-card-text>
              </div>
              <b-dropdown
                no-caret
                dropleft
                variant="flat-secondary"
                class="mx-0 mb-0 p-0 course-selector-list-item-button ml-auto"
                menu-class="dropdown-editable-list"
                toggle-class="p-50 rounded-pill"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                  />
                </template>
                <b-dropdown-item-button
                  v-if="selectable"
                  variant="primary"
                  @click.prevent="$emit('courseSelected')"
                >
                  <feather-icon
                    icon="CheckSquareIcon"
                    class="mr-1"
                  />
                  Выбрать
                </b-dropdown-item-button>
                <router-link
                  :target="external ? '_blank' : ''"
                  :to="{ name: 'cabinet.course', params: { course_id: course.id } }"
                >
                  <b-dropdown-item-button>
                    <feather-icon
                      icon="ExternalLinkIcon"
                      class="mr-1"
                    />
                    Подробнее
                  </b-dropdown-item-button>
                </router-link>
                <b-dropdown-divider v-if="canEditCourse" />
                <create-course-modal
                  v-if="canEditCourse"
                  :course="course"
                  uploadable
                  @updated="crs => $emit('updated', crs)"
                >
                  <template #activator="{show}">
                    <b-dropdown-item-button
                      @click.prevent="show"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="14"
                        class="mr-1"
                      />
                      Настроить
                    </b-dropdown-item-button>
                  </template>
                </create-course-modal>
                <b-dropdown-item-button
                  v-if="canDeleteCourse"
                  variant="danger"
                  @click.prevent="deleteCourse"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="14"
                    class="mr-1"
                  />
                  {{ $t('components.button.labels.delete') }}
                </b-dropdown-item-button>
              </b-dropdown>
            </div>

            <div class="flex-fill" />

            <b-card-text>
              <div class="d-flex align-items-center flex-wrap">
                <!--                <b-button-->
                <!--                  size="sm"-->
                <!--                  class="mr-50"-->
                <!--                  :variant="course.type === userType ? 'outline-primary' : 'outline-warning'"-->
                <!--                >-->
                <!--                  <span v-if="!courseIsReadyCourse">Пользовательский</span>-->
                <!--                  <span v-else>Базовый</span>-->
                <!--                </b-button>-->
                <b-button
                  v-if="course.subjects"
                  size="sm"
                  class="mr-50"
                  variant="outline-secondary"
                  @click.stop.prevent="$emit('subjectSelected', course.subjects_id)"
                >
                  {{ course.subjects.text }}
                </b-button>
                <!--                <b-button-->
                <!--                  v-if="course.grade"-->
                <!--                  size="sm"-->
                <!--                  variant="outline-secondary"-->
                <!--                  @click="$emit('gradeSelected', course.grade_id)"-->
                <!--                >-->
                <!--                  {{ course.grade.name }} класс-->
                <!--                </b-button>-->
                <div class="flex-fill" />
                <b-button
                  v-if="selectable"
                  size="sm"
                  variant="outline-primary"
                  @click.prevent="$emit('courseSelected')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="CheckSquareIcon"
                  />
                  Выбрать
                </b-button>
                <!--                <b-avatar-->
                <!--                  v-else-if="course.user"-->
                <!--                  size="31"-->
                <!--                  :src="course.user.image ? course.user.image.full_url : null"-->
                <!--                  class="badge-minimal"-->
                <!--                  :title="creatorFullName"-->
                <!--                />-->
              </div>
            </b-card-text>
          </b-card-body>
        </b-col>
      </b-row>

      <confirm-modal
        ref="deleteConfirm"
        :yes="'Удалить'"
        color="danger"
        :text="`Курс ${course.name} и все связанные с ним темы и уроки будут удалены`"
      />
    </b-card>
  </router-link>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardTitle, BCardText, BOverlay, BButton, BDropdown, BDropdownItemButton, BDropdownDivider,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import CourseImage from '@/components/new_courses/course/CourseImage.vue'
import CreateCourseModal from '@/components/new_courses/course/CreateCourseModal.vue'
import ConfirmModal from '@/components/page-elements/modals/ConfirmModal.vue'
import { adminRole, teacherRole, userRoles } from '@/store/user/index'
import { getUserFullName } from '../../../helpers'

export default {
  name: 'Course',
  components: {
    ConfirmModal,
    CreateCourseModal,
    CourseImage,
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    BCardBody,
    BOverlay,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BDropdownDivider,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    external: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMouseOver: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      userType: 'course/get_course_user_type',
      readyType: 'course/get_course_ready_type',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isAdmin() {
      return this.userRole === adminRole
    },
    courseIsReadyCourse() {
      return this.course.type === this.readyType
    },
    canEditCourse() {
      if (this.courseIsReadyCourse) {
        return this.$can('edit', 'ready-courses')
      }
      if (this.isAdmin) return true
      return this.course.user_id === this.user.id && this.$can('edit', 'courses')
    },
    canDeleteCourse() {
      if (this.courseIsReadyCourse) {
        return this.$can('delete', 'ready-courses')
      }
      if (this.isAdmin) return true
      return this.course.user_id === this.user.id && this.$can('delete', 'courses')
    },
    creatorFullName() {
      return getUserFullName(this.course.user)
    },
  },
  methods: {
    deleteCourse() {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$http.delete(`/courses/delete?id=${this.course.id},`)
              .then(() => {
                this.$emit('deleted', this.course)
              })
          }
        })
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  min-height: 150px;
}
</style>
