<template>
  <b-modal
    id="confirm-modal"
    ref="modal"
    v-model="dialog"
    size="sm"
    centered
    body-class="p-0"
    :title="title"
  >
    <div class="px-2 py-1">
      <p>{{ text }}</p>
    </div>
    <template #modal-footer>
      <div class="d-flex flex-fill align-items-center justify-content-between">
        <b-button
          :variant="color"
          type="button"
          class="mr-1"
          @click="confirm"
        >
          {{ yes }}
        </b-button>
        <b-button
          variant="flat-secondary"
          type="button"
          @click="cancel"
        >
          {{ no }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'
import VueI18n from '@/libs/i18n'

export default {
  name: 'ConfirmModal',
  components: {
    BModal,
    BButton,
  },
  props: {
    title: {
      type: String,
      default: VueI18n.t('modals.confirm.title'),
    },
    yes: {
      type: String,
      default: VueI18n.t('modals.confirm.yes'),
    },
    no: {
      type: String,
      default: VueI18n.t('modals.confirm.no'),
    },
    text: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
  }),
  methods: {
    open() {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    confirm() {
      this.resolve({
        result: true,
      })
      this.dialog = false
    },
    cancel() {
      this.resolve({ result: false })
      this.dialog = false
    },
  },
}
</script>

<style scoped>

</style>
