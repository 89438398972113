<template>
  <b-card class="mb-0 full-height">
    <b-row>
      <b-col
        cols="12"
        md="4"
        xl="2"
      >
        <!--        <b-button-->
        <!--          block-->
        <!--          class="mb-1"-->
        <!--          variant="primary"-->
        <!--          @click="$emit('openCreator')"-->
        <!--        >-->
        <!--          <feather-icon icon="PlusIcon" />-->
        <!--          Добавить-->
        <!--        </b-button>-->

        <div class="sticky-top">
          <div v-if="exerciseTypes.length">
            <p>
              Тип упражнения
            </p>
            <b-form-checkbox-group
              v-model="filter.types"
              @change="getExercises()"
            >
              <div class="d-flex flex-column">
                <b-form-checkbox
                  v-for="type in exerciseTypes"
                  :key="type.id"
                  :value="type.id"
                  class="mb-50"
                >
                  {{ type.name }}
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
          </div>

          <p class="mt-1">
            Предмет
          </p>
          <v-select
            v-model="filter.subjects"
            :options="subjects"
            multiple
            label="text"
            :reduce="s => s.id"
            placeholder="Все предметы"
          />

          <p class="mt-1">
            Класс
          </p>
          <v-select
            v-model="filter.grades"
            :options="grades"
            multiple
            label="name"
            :reduce="s => s.id"
            placeholder="Все классы"
          />

          <b-form-checkbox
            v-model="filter.own"
            class="mt-2"
            switch
            @change="getExercises()"
          >
            Показывать только мои
          </b-form-checkbox>

          <b-form-checkbox
            v-if="filter.own"
            v-model="filter.private"
            class="mt-1"
            switch
            @change="getExercises()"
          >
            Только личные
          </b-form-checkbox>
        </div>
      </b-col>
      <b-col>
        <b-input-group class="mb-1">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="filterInput"
            v-model="filter.search"
            type="search"
            :placeholder="$t('page.students.search.labels.input-search')"
            @keydown.enter="getExercises()"
            @input="!filter.search ? getExercises() : ''"
          />
        </b-input-group>
        <div class="mb-1 px-50">
          <b-row v-if="loading">
            <b-col
              v-for="(skeleton, key) in pagination.perPage"
              :key="key"
              cols="12"
              md="6"
              lg="4"
              xl="3"
              class="p-50"
            >
              <skeletor
                width="100%"
                height="158"
              />
            </b-col>
          </b-row>
          <b-row v-else-if="exercises.length">
            <b-col
              v-for="exercise in exercises"
              :key="exercise.id"
              cols="12"
              md="6"
              lg="4"
              xl="3"
              class="px-50 pb-50"
            >
              <b-card
                class="m-0 hoverable full-height cursor-pointer"
                :border-variant="exerciseSelected(exercise) ? 'primary' : 'secondary'"
                :bg-variant="exerciseSelected(exercise) ? 'primary' : ''"
                :class="{'text-white': exerciseSelected(exercise)}"
                @click="$emit('selected', exercise)"
              >
                <b-card-text
                  class="d-flex full-height flex-column justify-content-between"
                >
                  <div style="min-height: 100px">
                    <p class="font-medium-1 mb-0">
                      {{ exercise.name }}
                    </p>
                    <p
                      v-if="exercise.lessons"
                      class="font-small-3 mb-0 "
                      :class="{'text-muted': !exerciseSelected(exercise)}"
                    >
                      {{ exercise.lessons.name }}
                    </p>
                  </div>
                  <span
                    class="font-small-3"
                    :class="{'text-muted': !exerciseSelected(exercise)}"
                  >
                    Вопросов: {{ exercise.exercisesContents.length }}
                  </span>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div class="d-flex align-items-center justify-content-center mt-4">
                <h3 v-if="filter.search">
                  По запросу <span class="text-primary">{{ filter.search }}</span> ничего не найдено
                </h3>
                <h3 v-else>
                  Нет упражнений по заданным параметрам
                </h3>
              </div>
            </b-col>
          </b-row>
        </div>
        <skeletor
          v-if="loading && !exercises.length"
          width="200"
          height="30"
        />
        <b-pagination
          v-else-if="pagination.totalCount"
          v-model="pagination.page"
          :total-rows="pagination.totalCount"
          :per-page="pagination.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="page => pagination.page = page"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardText,
  // BButton,
  BPagination, BRow, BCol, BInputGroup, BFormInput, BFormCheckbox, BFormCheckboxGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'SelectExercise',
  components: {
    BCard,
    // BButton,
    BPagination,
    BRow,
    BCol,
    BCardText,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
    BInputGroupPrepend,
  },
  props: {
    selectedExercise: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    filter: {
      own: false,
      private: false,
      types: [],
      grades: [],
      subjects: [],
      search: '',
    },
    pagination: {
      page: 1,
      totalCount: null,
      perPage: 16,
    },
    exercises: [],
    loading: false,
  }),
  computed: {
    ...mapGetters({
      exerciseTypes: 'exercise/get_types',
      subjects: 'course/get_subjects',
      grades: 'course/get_grades',
      user: 'user/get_user',
    }),
  },
  watch: {
    'pagination.page': {
      handler() {
        this.getExercises()
      },
    },
    'filter.subjects': {
      handler() {
        this.getExercises()
      },
    },
    'filter.grades': {
      handler() {
        this.getExercises()
      },
    },
  },
  mounted() {
    if (!this.exerciseTypes.length) this.getExerciseTypes()
    if (!this.subjects.length) this.getSubjects()
    if (!this.grades.length) this.getGrades()
    this.getExercises()
  },
  methods: {
    ...mapActions({
      getExerciseTypes: 'exercise/getExerciseTypes',
      getSubjects: 'course/getSubjects',
      getGrades: 'course/getGrades',
    }),
    async getExercises() {
      this.loading = true
      const { items, _meta } = await this.$http.get('/exercises/index', {
        params: {
          user_id: this.filter.own ? this.user.id : null,
          private: this.filter.private ? 1 : null,
          exercise_types_id: this.filter.types.join(','),
          subjects_id: this.filter.subjects.join(','),
          grade_id: this.filter.grades.join(','),
          name: this.filter.search,
          limit: this.pagination.perPage,
          page: this.pagination.page,
          expand: 'lessons,exercisesContents',
        },
      })
      this.exercises = items
      this.pagination.totalCount = _meta.totalCount
      this.loading = false
    },
    exerciseSelected(exercise) {
      return this.selectedExercise && this.selectedExercise.id === exercise.id
    },
  },
}
</script>

<style scoped>

</style>
