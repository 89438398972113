<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      title="Комментарий к материалу"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template #default="{ hide }">
        <!-- form -->
        <b-form @submit.prevent="createComment">

          <div class="p-2">
            <!-- name -->
            <b-form-group
              label-for="text"
              label="Текст комментария"
            >
              <b-form-textarea
                id="text"
                v-model.trim="form.text"
                name="name"
                rows="3"
                placeholder="Введите текст"
                autofocus
              />
            </b-form-group>
          </div>
          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
              :disabled="!form.text"
            >
              Отправить
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="hide"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormTextarea, BModal,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { toast } from '../../helpers'

export default {
  name: 'StepCommentModal',
  components: {
    BModal,
    BButton,
    BForm,
    BFormTextarea,
    BFormGroup,
  },
  props: {
    stepId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    form: {
      text: null,
    },
    display: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  watch: {
    display() {
      this.form.text = null
    },
  },
  methods: {
    async createComment() {
      this.$http.post('/step-comments/create', { ...this.form, lesson_step_id: this.stepId, user_id: this.user.id })
        .then(() => {
          this.$emit('created', this.form)
          toast({ title: 'Комментарий отправлен', type: 'success' })
          this.display = false
        })
    },
    show() {
      this.display = true
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
