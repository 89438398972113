<template>
  <div class="mb-1">
    <b-form-input
      v-if="editing"
      v-model="element.data"
      class="mb-1"
      :placeholder="$t(`page.course.lesson-step.labels.${element.type}`)"
    />
    <b-embed
      v-if="element.type === 'video' && element.data"
      :key="element.id"
      type="embed"
      :src="element.data"
      allowfullscreen
      class="video-preview"
    />
  </div>
</template>

<script>
import { BEmbed, BFormInput } from 'bootstrap-vue'

export default {
  name: 'StepVideo',
  components: {
    BEmbed, BFormInput,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    element() {
      this.setData()
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      try {
        this.element.data = JSON.parse(this.element.data)
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
  },
}
</script>

<style scoped>

</style>
