<template>
  <div :class="editingOrPreviewing ? 'mb-3' : 'mb-1'">
    <div
      v-if="editingOrPreviewing"
      class="d-flex align-items-center mb-1"
    >
      <feather-icon
        class="drag-handle mr-1"
        size="20"
        icon="MoreHorizontalIcon"
      />
      <span>{{ $t(`page.course.lesson-step.labels.${element.type}`) }}</span>
      <div class="flex-fill" />
      <b-form-checkbox
        v-model="element.disabled"
        checked="true"
        class="custom-control-primary"
        switch
        :value="0"
        :unchecked-value="1"
        :title="element.disabled ? 'Выключен' : 'Активен'"
      />
      <b-button
        size="sm"
        variant="flat-secondary"
        class="btn-icon ml-50"
        @click="$emit('delete', index)"
      >
        <feather-icon icon="XIcon" />
      </b-button>
    </div>

    <step-title
      v-if="element.type === 'name'"
      :element="element"
      :editing="editingOrPreviewing"
    />
    <step-content
      v-else-if="element.type === 'text'"
      :element="element"
      :editing="editingOrPreviewing"
    />
    <step-image
      v-else-if="element.type === 'image'"
      :board="imageAsBoard"
      :hometask-id="hometaskId"
      :element="element"
      :editing="editingOrPreviewing"
    />
    <step-audio
      v-else-if="element.type === 'audio'"
      :element="element"
      :editing="editingOrPreviewing"
    />
    <step-video
      v-else-if="element.type === 'video'"
      :element="element"
      :editing="editingOrPreviewing"
    />
    <step-test
      v-else-if="element.type === 'test'"
      :element="element"
      :editing="editingOrPreviewing"
    />
    <step-exercise
      v-else-if="element.type === 'exercise'"
      :element="element"
      :editing="editingOrPreviewing"
    />
    <step-doc
      v-else-if="element.type === 'doc'"
      :element="element"
      :editing="editingOrPreviewing"
    />
    <step-iframe
      v-else-if="element.type === 'iframe'"
      :element="element"
      :editing="editingOrPreviewing"
    />
  </div>
</template>

<script>
import { BFormCheckbox, BButton } from 'bootstrap-vue'
import StepTitle from '@/components/new_courses/course/theme/lesson/step/components/elements/StepTitle.vue'
import StepContent from '@/components/new_courses/course/theme/lesson/step/components/elements/StepContent.vue'
import StepImage from '@/components/new_courses/course/theme/lesson/step/components/elements/StepImage.vue'
import StepVideo from '@/components/new_courses/course/theme/lesson/step/components/elements/StepVideo.vue'
import StepTest from '@/components/new_courses/course/theme/lesson/step/components/elements/StepTest.vue'
import StepExercise from '@/components/new_courses/course/theme/lesson/step/components/elements/StepExercise.vue'
import StepAudio from '@/components/new_courses/course/theme/lesson/step/components/elements/StepAudio.vue'
import StepDoc from '@/components/new_courses/course/theme/lesson/step/components/elements/StepDoc.vue'
import StepIframe from './elements/StepIframe.vue'

export default {
  name: 'StepElements',
  components: {
    StepIframe,
    StepDoc,
    StepAudio,
    StepExercise,
    StepTest,
    StepVideo,
    StepImage,
    StepContent,
    StepTitle,
    BFormCheckbox,
    BButton,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    editingOrPreviewing: {
      type: Boolean,
      default: false,
    },
    imageAsBoard: {
      type: Boolean,
      default: false,
    },
    hometaskId: {
      type: Number,
      default: null,
    },
  },
}
</script>

<style scoped>
.drag-handle {
  cursor: grab;
}
</style>
