<template>
  <div
    v-if="data.questions"
    class="mt-1"
  >
    <div>
      <b-form-group v-if="edit">
        <b-form-input
          v-model="data.questions.exercise"
          type="text"
          placeholder="Укажите задание"
        />
      </b-form-group>
      <h2
        v-else
        class="my-2 text-center"
      >
        {{ data.questions.exercise }}
      </h2>
    </div>

    <b-row>
      <b-col :cols="edit ? '' : 12">
        <step-audio
          :editing="edit"
          :element="data.questions.audio"
          input-placeholder="Добавьте аудиофайл (необязательно)"
        />
      </b-col>
      <b-col :cols="edit ? '' : 12">
        <step-image
          :editing="edit"
          :element="data.questions.image"
          input-placeholder="Добавьте изображение (необязательно)"
        />
      </b-col>
    </b-row>

    <div
      v-if="edit"
      class="mb-1 d-flex align-items-center justify-content-between"
    >
      <span>Варианты ответов</span>
      <b-alert
        :show="data.questions.canMultipleCorrect"
        class="m-0 px-50"
        variant="info"
      >
        Несколько правильных ответов
      </b-alert>
    </div>

    <b-row>
      <b-col
        v-for="(variant, key) in variants"
        :key="key"
        cols="12"
        :md="type == 1 ? 6 : 12"
      >
        <b-card
          v-if="edit"
          no-body
          :border-variant="isTeacher && variant.isCorrect ? 'success' : 'secondary'"
        >
          <div class="d-flex">
            <b-img
              v-if="variant.imagePreview"
              :src="variant.imagePreview"
              style="max-width: 200px;"
              class="image-preview "
            />
            <b-card-body>
              <b-form-input
                v-if="type != 3"
                v-model="variant.text"
                placeholder="Введите вариант ответа"
              />
              <b-form-file
                v-else
                :id="`step-test-answer-image-${key}`"
                v-model="variant.image"
                accept="image/*"
                :placeholder="$t('page.course.test.image')"
                :drop-placeholder="$t('components.file-input.drop-file-here')"
                :browse-text="$t('components.file-input.browse')"
                @input="file => variantImagePreview(key, file)"
              />
              <div class="mt-50 d-flex align-items-center justify-content-between">
                <b-form-checkbox
                  v-model="variant.isCorrect"
                >
                  Правильный вариант
                </b-form-checkbox>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon"
                  size="sm"
                  @click="variants.splice(key, 1)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </b-card-body>
          </div>
        </b-card>
        <b-card
          v-else-if="variant.text || variant.image_id || variant.imagePreview"
          :border-variant="isTeacher && variant.isCorrect ? 'success' : 'secondary'"
        >
          <div class="d-flex align-items-center justify-content-center">
            <span class="font-medium-2">{{ variant.text }}</span>
            <b-img
              v-if="variant.imagePreview"
              :src="variant.imagePreview"
              class="image-preview"
            />
            <store-image
              v-else-if="variant.image_id"
              :store-id="variant.image_id"
            />
          </div>
        </b-card>
      </b-col>

      <b-col
        v-if="edit"
        cols="12"
        :md="type == 1 ? 6 : 12"
      >
        <b-card
          border-variant="secondary"
          class="cursor-pointer"
          @click="variants.push({})"
        >
          <div class="py-50 d-flex flex-column align-items-center justify-content-center">
            <feather-icon
              size="33"
              class=""
              icon="PlusIcon"
            />
            <span class="mt-50">Добавить вариант ответа</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BFormInput, BFormCheckbox, BRow, BCol, BButton, BAlert, BFormGroup, BFormFile, BImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { teacherRole, userRoles } from '@/store/user/index'
import StepAudio from '@/components/new_courses/course/theme/lesson/step/components/elements/StepAudio.vue'
import StepImage from '@/components/new_courses/course/theme/lesson/step/components/elements/StepImage.vue'
import StoreImage from '@/components/store/StoreImage.vue'

export default {
  name: 'ExerciseButton',
  components: {
    StoreImage,
    StepImage,
    StepAudio,
    BCard,
    BFormInput,
    BFormCheckbox,
    BRow,
    BButton,
    BCol,
    BAlert,
    BFormGroup,
    BFormFile,
    BImg,
    BCardBody,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      required: true,
    },
    type: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    data: {},
    variants: [],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
  },
  watch: {
    data: {
      deep: true,
      handler(value) {
        this.$emit('updated', value)
      },
    },
    'data.questions.answers': {
      deep: true,
      handler() {
        this.data.questions.canMultipleCorrect = false
        let correct = 0
        this.variants.forEach(answer => {
          if (answer.isCorrect) correct += 1
        })
        if (correct > 1) this.data.questions.canMultipleCorrect = true
      },
    },
  },
  mounted() {
    this.parseQuestions()
  },
  methods: {
    variantImagePreview(variantIndex, file) {
      this.variants[variantIndex].imagePreview = URL.createObjectURL(file)
    },
    parseQuestions() {
      if (this.content.data) {
        try {
          this.data = JSON.parse(this.content.data)
        } catch (err) {
          this.data = { ...this.content.data }
        }
      }
      this.variants = this.data.questions.answers
      this.data.questions.image = { data: this.data.questions.image_id }
      this.data.questions.audio = { data: this.data.questions.audio_id }
    },
  },
}
</script>

<style scoped>

</style>
