<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      :title="title"
      hide-footer
      centered
      no-body
      size="xl"
      body-class="p-0"
    >
      <template #default="{hide}">
        <exercise-editor
          :exercise="exercise"
          @hideModal="hide"
          @selected="exerciseSelected"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import ExerciseEditor from '@/components/new_courses/course/theme/lesson/step/components/elements/exercise/ExerciseEditor.vue'

export default {
  name: 'ExericseEditorModal',
  components: {
    ExerciseEditor,
    BModal,
  },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    display: false,
    title: 'Добавление упражнения',
  }),
  methods: {
    show() {
      this.display = !this.display
    },
    exerciseSelected(exercise) {
      this.$emit('selected', exercise)
      this.display = false
    },
  },
}
</script>

<style scoped>

</style>
