<template>
  <div>
    <b-card
      no-body
      class="mb-0"
      :class="{'shadow-none': noShadow}"
    >
      <b-input-group class="w-100">
        <b-input-group-prepend
          v-if="withSearch"
          is-text
        >
          <feather-icon
            icon="SearchIcon"
            class="text-muted"
          />
        </b-input-group-prepend>
        <vue-autosuggest
          ref="autocomplete"
          v-model="query"
          class="flex-fill"
          :class="{'with-search': withSearch}"
          :suggestions="suggestions"
          :input-props="inputProps"
          :render-suggestion="renderSuggestion"
          :get-suggestion-value="getSuggestionValue"
          @input="fetchResults"
          @keydown.enter.prevent="onEnter"
          @selected="onSelect"
        />
      </b-input-group>
    </b-card>

    <div class="mt-50">
      <b-badge
        v-for="(exerciseTag, key) in selected"
        :key="key"
        class="mr-50 mb-50"
        variant="primary"
        :pill="pill"
      >
        <span>{{ exerciseTag.tags.name }}</span>
        <feather-icon
          class="ml-50 cursor-pointer"
          icon="XIcon"
          @click="removeTag(key)"
        />
      </b-badge>
    </div>
  </div>
</template>

<script>
import {
  BCard, BBadge, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    BCard,
    VueAutosuggest,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    pill: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
      timeout: null,
      selected: [],
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Начните вводить тег',
        class: 'autosuggest form-control bg-transparent',
        name: 'tags',
      },
      suggestions: [],
      prevQuery: '',
    }
  },
  computed: {
    notFoundForPreviousQuery() {
      return this.query.length > 4 && this.query.includes(this.prevQuery) && this.suggestions[0] && !this.suggestions[0].data.length
    },
  },
  watch: {
    tags(value) {
      this.selected = value
    },
  },
  beforeMount() {
    if (this.withSearch) this.inputProps.placeholder = 'Введите тег или поисковый запрос'
    this.selected = this.tags
  },
  methods: {
    addTag({ id, name }) {
      if (name) {
        let tagId = id
        const existing = this.suggestions[0].data.find(tag => tag.name === name)
        if (existing) tagId = existing.id
        if (!tagId && this.withSearch) {
          this.$emit('searchUpdated', name)
        } else {
          if (!this.selected.find(tag => tag.tags.name === name)) this.selected.push({ tags_id: tagId, tags: { id: tagId, name } })
          this.$emit('selected', this.selected)
          this.query = null
        }
      }
    },
    onSelect(selected) {
      if (selected) {
        const { id, name } = selected.item
        this.addTag({ id, name })
      }
    },
    onEnter() {
      this.addTag({ name: this.query })
    },
    async fetchResults() {
      const { query, $http } = this

      if (!query.length && this.withSearch) {
        this.$emit('searchUpdated', query)
        return
      }
      if (query.length < 3) return

      let items = []
      if (this.query && !this.notFoundForPreviousQuery) {
        const tags = await $http.get('/tags/index', {
          params: {
            name: query,
          },
        })
        items = tags.items
      }
      this.prevQuery = this.query
      this.suggestions = [{ data: items }]
    },
    renderSuggestion(suggestion) {
      return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name
    },
    async removeTag(index) {
      const tag = this.selected[index]
      this.selected.splice(index, 1)
      if (tag.exercises_id) {
        await this.$http.delete(`/exercises-tags/delete?id=${tag.id}`)
      }
      if (tag.steps_id) {
        await this.$http.delete(`/steps-tags/delete?id=${tag.id}`)
      }
      this.$emit('selected', this.selected)
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-autosuggest.scss";
.with-search {
  .autosuggest {
    &.form-control {
      border-top-left-radius: 0!important;
      border-bottom-left-radius: 0!important;
    }

  }
}
</style>

<style scoped lang="scss">
.input-group-text {
  background-color: transparent!important;
}
</style>
