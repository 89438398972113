<template>
  <b-pagination
    v-model="currentPage"
    :total-rows="totalRows"
    :per-page="perPage"
    first-number
    last-number
    class="pl-0 my-1"
    prev-class="prev-item"
    next-class="next-item"
  >
    <template #prev-text>
      <feather-icon
        icon="ChevronLeftIcon"
        size="18"
      />
    </template>
    <template #next-text>
      <feather-icon
        icon="ChevronRightIcon"
        size="18"
      />
    </template>
  </b-pagination>
</template>

<script>
import { BPagination } from 'bootstrap-vue'

export default {
  name: 'ListPagination',
  components: {
    BPagination,
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    totalNumber: {
      type: Number,
      required: true,
    },
    paginationUpdated: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      currentPage: this.page,
      perPage: this.limit,
      totalRows: this.totalNumber,
    }
  },
  watch: {
    currentPage() {
      this.paginationUpdated({ page: this.currentPage, limit: this.perPage })
    },
  },
}
</script>

<style scoped>

</style>
