<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
      lg="4"
      xl="3"
    >
      <b-row
        no-gutters
        class="mb-1"
      >
        <b-col class="mr-50">
          <create-course-modal
            :uploadable="$can('upload', 'courses')"
            @created="reloadList"
          >
            <template #activator="{show}">
              <b-button
                block
                variant="primary"
                @click="show"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                Курс
              </b-button>
            </template>
          </create-course-modal>
        </b-col>

        <b-col class="ml-50">
          <create-step-modal @created="(step) => $router.push({ name: 'cabinet.step', params: { step_id: step.id } })">
            <template #activator="{show}">
              <b-button
                block
                variant="warning"
                :disabled="creatingStep"
                style="padding: 0.786rem 0.7rem"
                @click="show"
              >
                <template v-if="!creatingStep">
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  Карточка
                </template>
                <template v-else>
                  <b-spinner small />
                </template>
              </b-button>
            </template>
          </create-step-modal>
        </b-col>
      </b-row>

      <courses-filter
        class="sticky-top"
        style="top: 108px"
        :filter="filter"
        :tabs="tabs"
        @updated="reloadList"
      />
    </b-col>
    <b-col
      cols="12"
      md="6"
      lg="8"
      xl="9"
    >
      <courses-list
        v-if="filter.tab === 0"
        ref="list"
        :filter="filter"
        @subjectSelected="addSubject"
        @gradeSelected="addGrade"
      />
      <steps-list
        v-else
        ref="list"
        :filter="filter"
        @subjectSelected="addSubject"
        @gradeSelected="addGrade"
        @tagsUpdated="tagsUpdated"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import CoursesFilter from '@/components/new_courses/CoursesFilter.vue'
import CoursesList from '@/components/new_courses/CoursesList.vue'
import CreateCourseModal from '@/components/new_courses/course/CreateCourseModal.vue'
import StepsList from '@/components/steps/StepsList.vue'
import CreateStepModal from '../../components/new_courses/course/theme/lesson/step/CreateStepModal.vue'

export default {
  name: 'Courses',
  components: {
    CreateStepModal,
    StepsList,
    CreateCourseModal,
    CoursesList,
    CoursesFilter,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  data: () => ({
    filter: {
      tab: 0,
      own: false,
      types: [],
      subjects: [],
      grades: [],
      courseId: null,
      lessonId: null,
      tags: [],
      favorite: false,
      filterType: null,
    },
    creatingStep: false,
    tabs: [
      {
        name: 'courses',
        title: 'Курсы',
      },
      {
        name: 'steps',
        title: 'Карточки',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  methods: {
    reloadList() {
      setTimeout(() => {
        this.$refs.list.load()
      })
    },
    tagsUpdated(tags) {
      const exerciseTags = tags.map(tag => tag.tags.id)
      this.filter.tags = [...exerciseTags]
      this.reloadList()
    },
    addSubject(subjectId) {
      if (!this.filter.subjects.includes(subjectId)) this.filter.subjects.push(subjectId)
      this.reloadList()
    },
    addGrade(gradeId) {
      if (!this.filter.grades.includes(gradeId)) this.filter.grades.push(gradeId)
      this.reloadList()
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
