<template>
  <b-card no-body>
    <b-tabs
      v-if="tabs.length"
      v-model="fl.tab"
      active-nav-item-class="rounded-top"
      nav-class="mb-0"
      fill
    >
      <b-tab
        v-for="(tab, key) in tabs"
        :key="`tab-${key}`"
        :title="tab.title"
      />
    </b-tabs>

    <div class="p-1">
      <p>База знаний</p>
      <b-form-radio-group
        v-if="!isStepsTab"
        v-model="fl.filterType"
        @change="reloadCourses"
      >
        <div class="d-flex flex-column">
          <b-form-radio
            :value="null"
            class="mb-50"
          >
            <span>Публичные курсы</span>
          </b-form-radio>
          <b-form-radio
            value="personal"
            class="mb-50"
          >
            <span>Личные курсы</span>
          </b-form-radio>
          <b-form-radio
            v-if="isAdmin"
            value="all"
            class="mb-50"
          >
            <span>Все</span>
          </b-form-radio>
        </div>
      </b-form-radio-group>
      <b-form-radio-group
        v-else
        v-model="fl.filterType"
        @change="reloadCourses"
      >
        <div class="d-flex flex-column">
          <b-form-radio
            :value="null"
            class="mb-50"
          >
            <span>Публичные карточки</span>
          </b-form-radio>
          <b-form-radio
            value="personal"
            class="mb-50"
          >
            <span>Мои карточки</span>
          </b-form-radio>
          <b-form-radio
            value="favorite"
            class="mb-50"
          >
            <span>Избранные карточки</span>
          </b-form-radio>
          <b-form-radio
            v-if="isAdmin"
            value="all"
            class="mb-50"
          >
            <span>Все</span>
          </b-form-radio>
        </div>
      </b-form-radio-group>
      <p class="mt-1">
        Предмет
      </p>
      <v-select
        v-model="fl.subjects"
        :options="subjects"
        multiple
        label="text"
        :reduce="s => s.id"
        placeholder="Все предметы"
        append-to-body
        :calculate-position="popperJS"
        @input="subjectChanged"
      />

      <p class="mt-1">
        Класс
      </p>
      <v-select
        v-model="fl.grades"
        :options="grades"
        multiple
        label="name"
        :reduce="s => s.id"
        placeholder="Все классы"
        append-to-body
        :calculate-position="popperJS"
        @input="gradeChanged"
      />

      <div v-if="isStepsTab && courses.length">
        <p class="mt-1">
          Курс (учебник)
        </p>
        <v-select
          v-model="fl.courseId"
          :options="courses"
          label="name"
          :loading="loadingCourses"
          :reduce="i => i.id"
          placeholder="Все курсы"
          append-to-body
          :calculate-position="popperJS"
          @input="reloadCourses"
        />
      </div>

      <template v-if="fl.courseId">
        <p class="mt-1">
          Урок
        </p>
        <v-select
          v-model="fl.lessonId"
          :options="lessons"
          :loading="loadingLessons"
          label="name"
          :reduce="i => i.id"
          placeholder="Все уроки"
          append-to-body
          :calculate-position="popperJS"
          @input="reloadCourses"
        />
      </template>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormRadioGroup, BFormRadio, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { restoreFilter, saveFilters, withPopper } from '@/helpers'
import { adminRole, userRoles } from '../../store/user'

export default {
  name: 'CoursesFilter',
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BFormRadioGroup,
    BFormRadio,
    vSelect,
    BTab,
    BTabs,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fl: this.filter,
      courses: [],
      lessons: [],
      loadingCourses: false,
      loadingLessons: false,
      typeTab: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      subjects: 'course/get_subjects',
      grades: 'course/get_grades',
    }),
    isStepsTab() {
      return this.tabs[this.fl.tab] && this.tabs[this.fl.tab].name === 'steps'
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isAdmin() {
      return this.userRole === adminRole
    },
  },
  watch: {
    'fl.tab': {
      handler() {
        this.filter.filterType = null
        saveFilters(this.$router, this.filter)
      },
    },
    'fl.courseId': {
      handler() {
        this.getLessons()
      },
    },
    '$route.query': {
      handler() {
        this.restoreFilter()
      },
    },
  },
  beforeMount() {
    this.restoreFilter()
  },
  async mounted() {
    if (!this.subjects.length) this.getSubjects()
    if (!this.grades.length) this.getGrades()

    this.getCoursesFilterItems()
  },
  methods: {
    ...mapActions({
      getSubjects: 'course/getSubjects',
      getGrades: 'course/getGrades',
    }),
    restoreFilter() {
      const fl = { ...restoreFilter(this.$route, this.fl) }
      if (fl.steps) this.typeTab = 1
      this.fl = Object.assign(this.fl, fl)
    },
    reloadCourses() {
      saveFilters(this.$router, this.filter)
      this.$emit('updated')
    },
    subjectChanged() {
      this.getCoursesFilterItems()
      this.reloadCourses()
    },
    gradeChanged() {
      this.getCoursesFilterItems()
      this.reloadCourses()
    },
    async getCoursesFilterItems() {
      this.loadingCourses = true
      const { items } = await this.$http.get('/courses/index', {
        params: {
          subjects_id: this.fl.subjects.join(','),
          grade_id: this.fl.grades.join(','),
          page: 1,
          limit: 100,
        },
      })
      this.courses = items
      this.loadingCourses = false
    },
    async getLessons() {
      this.loadingLessons = true
      const { items } = await this.$http.get('/lessons/index', {
        params: {
          coursesId: this.fl.courseId,
        },
      })
      this.lessons = items
      this.loadingLessons = false
    },
    popperJS(dropdownList, component, { width }) {
      withPopper(dropdownList, component, { width })
    },
  },
}
</script>

<style lang="scss">

</style>
