<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      :title="title"
      hide-footer
      centered
      no-body
      size="lg"
      body-class="p-0"
    >
      <template #default="{ }">
        <!-- form -->
        <b-form @submit.prevent="saveCourse">
          <b-tabs
            v-if="uploadable"
            v-model="creationType"
            fill
            nav-class="m-0"
          >
            <b-tab title="Ручное заполнение" />
            <b-tab title="Загрузить из файлов" />
            <b-tab
              v-if="!course"
              title="Старая загрузка"
            />
          </b-tabs>

          <div class="p-2">
            <template v-if="creationType === 0">
              <!-- name -->
              <b-form-group
                label-for="name"
                :label="$t('page.courses.form.labels.name')"
              >
                <b-form-input
                  id="name"
                  v-model="form.name"
                  v-validate="'required'"
                  name="name"
                  :state="errors.first('name') ? false : null"
                  :placeholder="$t('page.courses.form.labels.name')"
                  autofocus
                />
                <small class="text-danger">{{ errors.first('name') }}</small>
              </b-form-group>
              <!-- description -->
              <b-form-group
                label-for="description"
                :label="$t('page.courses.form.labels.description')"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  name="description"
                  :state="errors.first('description') ? false : null"
                  :placeholder="$t('page.courses.form.labels.description')"
                />
                <small class="text-danger">{{ errors.first('description') }}</small>
              </b-form-group>
              <!-- subject -->
              <b-form-group
                :label="$t('page.courses.form.labels.subject')"
                label-for="subject"
              >
                <v-select
                  id="subject"
                  v-model="form.subjects_id"
                  v-validate="'required'"
                  :options="subjects"
                  clearable
                  label="text"
                  class="d-block"
                  :reduce="s => s.id"
                  placeholder="Выберите предмет"
                  name="subject"
                  :state="errors.first('subject') ? false : null"
                >
                  <template slot="no-options">
                    {{ $t('components.select.no-items') }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors.first('subject') }}</small>
              </b-form-group>
              <!-- grade -->
              <b-form-group
                label="Класс"
                label-for="subject"
              >
                <v-select
                  id="grade"
                  v-model="form.grade_id"
                  v-validate="'required'"
                  :options="grades"
                  clearable
                  label="name"
                  class="d-block"
                  :reduce="s => s.id"
                  placeholder="Выберите класс"
                  name="grade"
                  :state="errors.first('grade') ? false : null"
                >
                  <template slot="no-options">
                    {{ $t('components.select.no-items') }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors.first('grade') }}</small>
              </b-form-group>
              <!-- image -->
              <b-form-group
                :label="$t('page.courses.form.labels.image')"
                label-for="image"
              >
                <b-form-file
                  id="image"
                  v-model="form.image_file"
                  accept=".jpg, .png, .jpeg"
                  :state="!!errors.length ? false : null"
                  :placeholder="$t('components.file-input.chose-file')"
                  :drop-placeholder="$t('components.file-input.drop-file-here')"
                  :browse-text="$t('components.file-input.browse')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </template>
            <template v-else-if="creationType === 1">
              <upload-course-form
                ref="uploadForm"
                :subjects="subjects"
                :grades="grades"
                :course="course"
              />
            </template>
            <template v-else>
              <upload-course-form-old
                ref="uploadFormOld"
                :subjects="subjects"
                :grades="grades"
              />
            </template>

            <b-form-radio-group
              v-if="isAdmin && (!form.id || form.user_id)"
              v-model="form.private"
              class="mt-1 d-flex flex-column"
              name="private"
            >
              <b-form-radio
                :value="false"
              >
                Публичный
              </b-form-radio>
              <b-form-radio
                class="mt-50"
                :value="true"
              >
                Личный (недоступен другим пользователям)
              </b-form-radio>
            </b-form-radio-group>
          </div>

          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
              :disabled="loading"
            >
              <template v-if="loading">
                <b-spinner small />
              </template>
              <template v-else>
                <span v-if="!course">{{ $t('page.courses.form.buttons.create') }}</span>
                <span v-else>{{ $t('components.button.labels.save') }}</span>
              </template>
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="display = false"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BModal, BFormTextarea, BFormFile, BTabs, BTab,
  BFormRadioGroup, BFormRadio, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import CourseSelectorMixin from '@/components/courses/course/CourseSelectorMixin'
import UploadCourseForm from './UploadCourseForm.vue'
import { adminRole, teacherRole, userRoles } from '@/store/user/index'
import UploadCourseFormOld from './UploadCourseFormOld.vue'
import { toast } from '../../../helpers'

export default {
  name: 'CreateCourseModal',
  components: {
    UploadCourseFormOld,
    UploadCourseForm,
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BFormFile,
    BTabs,
    BTab,
    BFormRadioGroup,
    BFormRadio,
    BSpinner,
  },
  mixins: [CourseSelectorMixin],
  props: {
    uploadable: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      name: null,
      description: null,
      subjects_id: null,
      image_file: null,
      grade_id: null,
      private: false,
    },
    display: false,
    creationType: 0,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      subjects: 'course/get_subjects',
      grades: 'course/get_grades',
      userType: 'course/get_course_user_type',
      readyType: 'course/get_course_ready_type',
    }),
    title() {
      return this.course ? 'Редактирование курса' : 'Создание курса'
    },
    canUpload() {
      return this.uploadable && this.$can('delete', 'courses')
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isAdmin() {
      return this.userRole === adminRole
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    dict() {
      return {
        custom: {
          name: {
            required: 'Необходимо указать название курса',
          },
          subject: {
            required: 'Необходимо указать предмет',
          },
          grade: {
            required: 'Необходимо указать класс',
          },
          excel_file: {
            required: 'Не выбран файл Excel',
          },
          images_file: {
            required: 'Не выбран архив с изображениями',
          },
          archive: {
            required: 'Не выбран архив с папками',
          },
        },
      }
    },
  },
  watch: {
    display() {
      if (!this.course) {
        this.form = {
          name: null,
          description: null,
          subjects_id: null,
          image_file: null,
          grade_id: null,
          private: !this.isAdmin,
        }
        this.creationType = 0
      }
    },
  },
  async created() {
    if (!this.subjects.length) this.getSubjects()
    if (!this.grades.length) this.getGrades()
    if (this.course) this.form = { ...this.course }
    this.$validator.localize('ru', this.dict)
  },
  methods: {
    ...mapActions({
      getSubjects: 'course/getSubjects',
      getGrades: 'course/getGrades',
    }),
    async saveCourse() {
      await this.$validator.validate().then(valid => {
        if (valid) {
          if (this.isTeacher) {
            this.form.type = this.userType
          } else {
            this.form.type = this.readyType
          }
          if (this.creationType === 0) {
            if (this.course) this.updateCourse()
            else this.createCourse()
          } else if (this.creationType === 1) {
            this.uploadCourse()
          } else {
            this.uploadCourseOld()
          }
        }
      })
    },
    async updateCourse() {
      if (this.form.image_file instanceof File) {
        const image = await this.$http.storeCreate(this.form.image_file)
        this.form.image_id = image.id
      }
      this.loading = true
      this.$http.put(`/courses/update?id=${this.form.id}`, { ...this.form }, {
        params: {
          expand: 'user.image,user.usersTeachers,image,subjects,grade',
        },
      })
        .then(data => {
          this.$emit('updated', data)
          this.display = false
        })
        .catch(errors => {
          this.$refs.form.setErrors(errors)
        })
      this.loading = false
    },
    async createCourse() {
      if (this.form.image_file) {
        const image = await this.$http.storeCreate(this.form.image_file)
        this.form.image_id = image.id
      }
      this.loading = true
      this.$http.post('/courses/create', { ...this.form, user_id: this.user.id }, {
        params: {
          expand: 'user,image,subjects',
        },
      })
        .then(data => {
          this.$emit('created', data)
          this.display = false
        })
        .catch(() => {})
      this.loading = false
    },
    async uploadCourse() {
      this.loading = true
      const data = await this.$refs.uploadForm.uploadCourse()
      if (data !== false) {
        this.$emit('created', data)
        this.display = false
        toast({ title: 'Курс загружен', type: 'success' })
      }
      this.loading = false
    },
    async uploadCourseOld() {
      this.loading = true
      const data = await this.$refs.uploadFormOld.uploadCourse()
      if (data !== false) {
        this.$emit('created', data)
        this.display = false
        toast({ title: 'Курс загружен', type: 'success' })
      }
      this.loading = false
    },
    show() {
      this.display = !this.display
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
