<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      :title="title"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template #default="{ hide }">
        <!-- form -->
        <b-form @submit.prevent="saveExercise">

          <div class="p-2">
            <b-form-group
              label-for="name"
              label="Название"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                v-validate="'required'"
                required
                placeholder="Введите название"
                autofocus
                name="name"
                :state="errors.first('name') ? false : null"
              />
              <small class="text-danger">{{ errors.first('name') }}</small>
            </b-form-group>
            <b-row v-if="!course">
              <b-col
                cols="12"
              >
                <b-form-group
                  label-for="subject_id"
                  label="Предмет"
                >
                  <v-select
                    id="subject_id"
                    v-model="form.subject_id"
                    :options="subjects"
                    label="text"
                    name="subject_id"
                    :reduce="s => s.id"
                    placeholder="Выберите предмет"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group
                  label-for="grade_id"
                  label="Класс"
                >
                  <v-select
                    id="grade_id"
                    v-model="form.grade_id"
                    :options="grades"
                    label="name"
                    name="grade_id"
                    :reduce="s => s.id"
                    placeholder="Выберите класс"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label-for="grade_id"
                  label="Тип упражнения"
                >
                  <v-select
                    id="exercise_types_id"
                    v-model="form.exercise_types_id"
                    :options="exerciseTypes"
                    label="name"
                    name="grade_id"
                    :reduce="s => s.value"
                    placeholder="Выберите тип"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              label-for="autocomplete"
              label="Теги"
            >
              <tags-autocomplete
                pill
                no-shadow
                :tags="form.exercisesTags"
                @selected="(selected) => form.exercisesTags = selected"
              />
            </b-form-group>

            <b-form-radio-group
              v-if="!form.id || form.user_id"
              v-model="form.private"
              class="mt-1"
              name="private"
            >
              <b-form-radio
                :value="false"
              >
                Публичное
              </b-form-radio>
              <b-form-radio
                class="mt-50"
                :value="true"
              >
                Личное (недоступна другим пользователям)
              </b-form-radio>
            </b-form-radio-group>
          </div>

          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
            >
              <b-spinner
                v-if="saving"
                small
                class="mr-50"
              />
              <span v-if="saving">Сохранение</span>
              <span v-else-if="!form.id">Создать</span>
              <span v-else>Сохранить</span>
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="hide"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BModal, BFormRadioGroup, BFormRadio, BSpinner, BCol, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import TagsAutocomplete from '../../page-elements/filters/TagsAutocomplete.vue'

export default {
  name: 'CreateExerciseModal',
  components: {
    TagsAutocomplete,
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BSpinner,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    course: {
      type: Object,
      default: null,
    },
    lesson: {
      type: Object,
      default: null,
    },
    exercise: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      name: null,
      type: 1,
      grade_id: null,
      subject_id: null,
      private: false,
      exercisesTags: [],
      disabled: 0,
      exercise_types_id: 1,
    },
    display: false,
    title: 'Создание упражнения',
    saving: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      subjects: 'course/get_subjects',
      grades: 'course/get_grades',
    }),
    exerciseTypes() {
      return [
        {
          value: 1,
          name: 'Выбор правильного ответа',
        },
        {
          value: 2,
          name: 'Сопоставление вариантов ответа',
        },
      ]
    },
    dict() {
      return {
        custom: {
          name: {
            required: 'Название упражнения обязательно',
          },
        },
      }
    },
    apiUrl() {
      return '/exercises'
    },
  },
  watch: {
    display() {
      if (!this.exercise) {
        this.form = {
          name: null,
          type: 1,
          grade_id: this.course?.grade_id ?? null,
          subject_id: this.course?.subjects_id ?? null,
          private: false,
          disabled: 0,
          exercisesTags: [],
          exercise_types_id: 1,
        }
      } else {
        this.form = { ...this.exercise }
      }
    },
  },
  mounted() {
    if (this.exercise) {
      this.form = { ...this.exercise }
      this.title = 'Обновление карточки'
    }

    this.$validator.localize('ru', this.dict)
    if (!this.subjects.length) this.getSubjects()
    if (!this.grades.length) this.getGrades()
  },
  methods: {
    ...mapActions({
      getSubjects: 'course/getSubjects',
      getGrades: 'course/getGrades',
    }),
    async saveExercise() {
      this.saving = true

      await this.$validator.validate().then(async valid => {
        if (valid) {
          if (this.exercise) await this.updateExercise()
          else await this.createExercise()
        }
      })

      this.$eventBus.$emit('page-title', this.form.name)
    },
    async createExercise() {
      const { apiUrl, form } = this

      const {
        name, type, subject_id, grade_id, private: privateMark, disabled, exercise_types_id, exercisesTags,
      } = form

      await this.$http.post(`${apiUrl}/create?expand=exercisesContents,exercisesTags.tags`, {
        ...{
          name,
          type,
          subject_id,
          grade_id,
          private: privateMark,
          is_template: 0,
          disabled,
          exercise_types_id,
        },
        user_id: this.user.id,
        lesson_id: this.lesson?.id,
      })
        .then(async response => {
          this.form = { ...response, exercisesTags }
          await this.saveTags()
          this.$emit('created', response)
          this.display = false
        })
      this.saving = false
    },
    async updateExercise() {
      const { apiUrl, form } = this

      const {
        name, type, subject_id, grade_id, private: privateMark, disabled, exercise_types_id, exercisesTags,
      } = form

      await this.$http.put(`${apiUrl}/update?id=${this.exercise.id}&expand=exercisesContents,exercisesTags.tags`, {
        name,
        type,
        subject_id,
        grade_id,
        private: privateMark,
        is_template: 0,
        disabled,
        exercise_types_id,
      })
        .then(async response => {
          this.form = { ...response, exercisesTags }
          await this.saveTags()
          this.$emit('updated', response)
          this.display = false
        })
      this.saving = false
    },
    async saveTags() {
      this.form.exercisesTags = await Promise.all(this.form.exercisesTags.map(async exerciseTag => {
        const tag = { ...exerciseTag }
        if (!tag.tags_id) {
          const { id } = await this.$http.post('/tags/create', { ...tag.tags })
          tag.tags_id = id
        }
        if (!tag.id) {
          tag.exercises_id = this.form.id
          tag.user_id = this.form.user_id
          const { id } = await this.$http.post('/exercises-tags/create', tag)
          tag.id = id
        }

        return tag
      }))
    },
    show() {
      this.display = true
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
