<template>
  <div>
    <b-card border-variant="secondary">
      <div class="d-flex">
        <div
          v-if="imagePreviewUrl || answer.image_id"
          style="flex-basis: 30%"
          class="mr-1"
        >
          <b-img
            v-if="imagePreviewUrl"
            :src="imagePreviewUrl"
            class="image-preview"
          />
          <store-image
            v-else-if="answer.image_id"
            :store-id="answer.image_id"
            image-class="image-preview"
          />
        </div>
        <div class="flex-fill">
          <div class="d-flex align-items-center mb-1">
            <b-button
              type="button"
              :variant="answer.isCorrect ? 'success' : 'outline-secondary'"
              class="mr-1"
              @click="answer.isCorrect = !answer.isCorrect"
            >
              {{ $t(`page.course.test.correct`) }}
            </b-button>

            <b-form-input
              v-if="!answer.image_id"
              :id="`step-test-answer-text-${index}`"
              v-model="answer.text"
              class="flex-fill"
              :placeholder="$t(`page.course.test.answer`)"
            />
            <b-button
              v-else
              block
              variant="secondary"
              @click="deleteImage()"
            >
              Убрать картинку
            </b-button>

            <b-button
              type="button"
              variant="flat-danger"
              class="btn-icon ml-1"
              :disabled="!deletable"
              @click="$emit('delete')"
            >
              <feather-icon
                icon="TrashIcon"
                size="15"
              />
            </b-button>
          </div>

          <b-form-file
            :id="`step-test-answer-image-${index}`"
            v-model="file"
            accept=".jpg, .png, .jpeg"
            :placeholder="$t('page.course.test.image')"
            :drop-placeholder="$t('components.file-input.drop-file-here')"
            :browse-text="$t('components.file-input.browse')"
            @input="file => uploadAnswerImage(file)"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormInput, BFormFile, BImg,
} from 'bootstrap-vue'
import StoreImage from '@/components/store/StoreImage.vue'

export default {
  name: 'LessonTestAnswer',
  components: {
    StoreImage, BCard, BButton, BFormInput, BFormFile, BImg,
  },
  props: {
    answer: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imagePreviewUrl: null,
    file: null,
  }),
  methods: {
    async uploadAnswerImage(file) {
      if (file) {
        this.imagePreviewUrl = URL.createObjectURL(file)
        const { id } = await this.$http.storeCreate(file)
        this.answer.image_id = id
      }
    },
    async deleteImage() {
      this.imagePreviewUrl = null
      this.answer.image_id = null
      this.file = null
    },
  },
}
</script>

<style scoped>

</style>
