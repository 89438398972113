<template>
  <div
    v-if="isTeacher"
    class="mb-1"
  >
    <b-button-group class="w-100">
      <b-button
        class="w-50"
        :variant="exercisesMode == 'training' ? 'primary' : 'outline-primary'"
        :disabled="disabled"
        @click="exerciseModeChanged('training')"
      >
        {{ $t('page.video-stream.stream-exercises.labels.training') }}
      </b-button>
      <b-button
        class="w-50"
        :variant="exercisesMode == 'testing' ? 'primary' : 'outline-primary'"
        :disabled="disabled"
        @click="exerciseModeChanged('testing')"
      >
        {{ $t('page.video-stream.stream-exercises.labels.testing') }}
      </b-button>
    </b-button-group>
  </div>
  <div v-else>
    <h3 class="font-medium-5 text-center">
      <span v-if="isTestingMode">{{ $t('page.video-stream.stream-exercises.labels.testing') }}</span>
      <span v-else>{{ $t('page.video-stream.stream-exercises.labels.training') }}</span>
    </h3>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButtonGroup, BButton } from 'bootstrap-vue'
import { teacherRole, userRoles } from '@/store/user'

export default {
  name: 'ExerciseModeSelector',
  components: {
    BButtonGroup,
    BButton,
  },
  props: {
    exercisesMode: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    exerciseModeChanged: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    isTestingMode() {
      return this.exercisesMode === 'testing'
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
  },
}
</script>

<style scoped>

</style>
