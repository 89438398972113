<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      title="Добавление презентации"
      hide-footer
      centered
      no-body
      size="sm"
      body-class="p-0"
    >
      <template #default="{hide}">
        <form @submit.prevent="save">
          <div class="p-2">
            <p>Укажите файл презентации. После загрузки, он будет разбит на ряд картинок (1 слайд = 1 картинка).</p>
            <p v-if="step.lesson_id">
              Каждая картинка будет добавлена в виде отдельной карточки
            </p>
            <p v-else>
              Каждая картинка будет добавлена в виде отдельного элемента
            </p>

            <b-form-file
              v-model="file"
              required
              :disabled="saving"
              accept=".ppt, .pptx"
              placeholder="Файл презентации (.ppt, .pptx)"
              :drop-placeholder="$t('components.file-input.drop-file-here')"
              :browse-text="$t('components.file-input.browse')"
            />
          </div>

          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
              :disabled="saving"
            >
              <b-spinner
                v-if="saving"
                small
                class="mr-50"
              />
              <span v-if="saving">Сохранение</span>
              <span v-else>Сохранить</span>
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="hide"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BFormFile, BButton, BSpinner,
} from 'bootstrap-vue'
import { toast } from '../../../../../../../../../helpers'

export default {
  name: 'AddPresentationModal',
  components: {
    BModal,
    BFormFile,
    BButton,
    BSpinner,
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    display: false,
    saving: false,
    file: null,
  }),
  methods: {
    show() {
      this.display = !this.display
    },
    async save() {
      this.saving = true

      const formData = new FormData()
      formData.append('presentation', this.file)
      formData.append('step_id', this.step.id)

      await this.$http.post('lesson-steps/upload-presentation', formData).then(() => {
        this.file = null
        this.display = false
        if (!this.step.lesson_id) this.$emit('onUpdate')
        else {
          toast({
            title: 'Презентация добавлена',
            text: 'Презентация была добавлена в виде отдельных шагов',
            type: 'success',
          })
        }
      }).catch(() => {})

      this.saving = false
    },
  },
}
</script>

<style scoped>

</style>
