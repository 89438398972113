<template>
  <div class="mb-0 full-height">
    <template v-if="loading">
      <skeletor
        width="100%"
        height="450px"
      />
    </template>
    <template v-else>
      <!--      <create-exercise-type-selector-->
      <!--        v-if="!exercise.exercise_types_id"-->
      <!--        @openSelector="$emit('openSelector')"-->
      <!--        @typeSelected="typeSelected"-->
      <!--      />-->
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          ref="form"
          class="full-height d-flex flex-column"
          @submit.prevent="saveExercise"
        >
          <div class="d-flex align-items-center mb-1">
            <b-button
              variant="flat-secondary"
              type="button"
              @click="$emit('openSelector')"
            >
              <feather-icon
                class="mr-50"
                icon="ArrowLeftIcon"
              />
              Назад
            </b-button>
            <div class="flex-fill" />
            <create-exercise-modal
              v-if="editable"
              :exercise="exercise"
            >
              <template #activator="{show}">
                <b-button
                  class="mr-50"
                  variant="outline-primary"
                  @click="show"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  Настроить
                </b-button>
              </template>
            </create-exercise-modal>
            <b-button
              variant="success"
              type="submit"
              :disabled="invalid || saving"
            >
              <template v-if="!saving">
                <feather-icon
                  class="mr-50"
                  icon="CheckIcon"
                />
                Сохранить
              </template>
              <template v-else>
                <b-spinner small />
                <span class="ml-50">Сохранение...</span>
              </template>
            </b-button>
          </div>
          <b-overlay
            :show="saving"
            rounded="sm"
          >
            <b-card class="full-height">
              <b-card-text :key="saveUpdate">
                <create-exercise-contents
                  :exercise="exercise"
                  :editable="editable"
                  @contentDeleted="contentDeleted"
                  @contentsUpdated="contentsUpdated"
                />
              </b-card-text>
            </b-card>
          </b-overlay>
        </b-form>
      </validation-observer>
    </template>
  </div>
</template>

<script>
import {
  BCard, BButton, BCardText, BForm, BSpinner, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import CreateExerciseContents from '@/components/new_courses/course/theme/lesson/step/components/elements/exercise/editor/CreateExerciseContents.vue'
import CreateExerciseModal from '../../../../../../../../../exercise/exercise/CreateExerciseModal.vue'

export default {
  name: 'CreateExercise',
  components: {
    CreateExerciseModal,
    CreateExerciseContents,
    BCard,
    BButton,
    BCardText,
    BForm,
    ValidationObserver,
    BSpinner,
    BOverlay,
  },
  props: {
    existingExercise: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    exercise: {
      exercise_types_id: null,
      private: false,
      disabled: 0,
      exercisesContents: [],
      tags: [],
      exercisesTags: [],
    },
    saving: false,
    deteledContents: [],
    saveUpdate: 0,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      subjects: 'course/get_subjects',
      grades: 'course/get_grades',
    }),
  },
  watch: {
    existingExercise(value) {
      if (value) this.exercise = { ...value }
    },
  },
  beforeMount() {
    if (this.existingExercise) this.exercise = { ...this.existingExercise }
    if (!this.subjects.length) this.getSubjects()
    if (!this.grades.length) this.getGrades()
  },
  methods: {
    ...mapActions({
      getSubjects: 'course/getSubjects',
      getGrades: 'course/getGrades',
    }),
    contentDeleted(content) {
      this.deteledContents.push(content)
    },
    contentsUpdated(contents) {
      this.$set(this.exercise, 'exercisesContents', contents)
    },
    typeSelected(type) {
      this.exercise.exercise_types_id = type.id
    },
    async saveExercise() {
      this.exercise.exercisesTags = await Promise.all(this.exercise.exercisesTags.map(async exerciseTag => {
        const exrTag = { ...exerciseTag }
        if (!exrTag.tags_id) {
          const { id } = await this.$http.post('/tags/create', { ...exrTag.tags })
          exrTag.tags_id = id
        }
        if (!exrTag.id) {
          exrTag.exercises_id = this.exercise.id
          exrTag.user_id = this.exercise.user_id
          const { id } = await this.$http.post('/exercises-tags/create', exrTag)
          exrTag.id = id
        }

        return exrTag
      }))
      if (this.exercise.id) await this.updateExercise()
      else await this.createExercise()
    },
    async createExercise() {
      this.saving = true
      this.$http.post('/exercises/create', {
        ...this.exercise,
        user_id: this.user.id,
        disabled: this.exercise.disabled ? 1 : 0,
      }, {
        params: {
          expand: 'exercisesContents,exercisesTags.tags',
        },
      })
        .then(async response => {
          await this.saveContents()
          this.saving = false
          this.$router.push({ name: 'cabinet.exercise', params: { exercise_id: response.id } })
        })
        .catch(() => {
          this.saving = false
        })
    },
    async updateExercise() {
      this.saving = true
      this.$http.put(`/exercises/update?id=${this.exercise.id}`, {
        ...this.exercise,
        disabled: this.exercise.disabled ? 1 : 0,
      }, {
        params: {
          expand: 'exercisesContents,exercisesTags.tags',
        },
      })
        .then(async () => {
          await this.saveContents()
          this.saving = false
        })
        .catch(() => {
          this.saving = false
        })
    },
    async saveContents() {
      await Promise.all(this.deteledContents.map(async content => {
        if (content.id) await this.$http.delete(`/exercises-content/delete?id=${content.id}`)
      }))
      this.deteledContents = []
      this.exercise.exercisesContents = await Promise.all(this.exercise.exercisesContents.map(async content => {
        const cont = {
          ...content,
          exercises_id: this.exercise.id,
        }

        try {
          cont.data = JSON.parse(cont.data)
          // eslint-disable-next-line no-empty
        } catch (err) {}

        const { questions } = cont.data
        if (questions.audio && questions.audio.file) {
          const { id } = await this.$http.storeCreate(questions.audio.file)
          cont.data.questions.audio_id = id
          delete cont.data.questions.audio
        }
        if (questions.image && questions.image.file) {
          const { id } = await this.$http.storeCreate(questions.image.file)
          cont.data.questions.image_id = id
          delete cont.data.questions.image
        }

        cont.data.questions.answers = await Promise.all(questions.answers.map(async answer => {
          const ans = { ...answer }
          if (ans.image) {
            const { id } = await this.$http.storeCreate(ans.image)
            ans.image_id = id
            delete ans.image
          }
          return ans
        }))

        cont.data = JSON.stringify(cont.data)

        if (cont.id) {
          await this.$http.put(`/exercises-content/update?id=${cont.id}`, cont)
        } else {
          const { id } = await this.$http.post('/exercises-content/create', cont)
          cont.id = id
        }

        return cont
      }))
      this.saveUpdate += 1
    },
  },
}
</script>

<style scoped>

</style>
