<template>
  <div class="mb-1">
    <div :key="updateExercise">
      <stream-exercise-questions
        v-if="element.exercise && element.exercise.exercisesContents.length"
        :exercise-questions="element.exercise.exercisesContents"
        in-step
      />
    </div>

    <exericse-editor-modal
      v-if="editing"
      :exercise="element.exercise"
      @selected="exerciseSelected"
    >
      <template #activator="{show}">
        <div class="d-flex align-items-center justify-content-center">
          <b-button
            variant="flat-primary"
            @click="show"
          >
            <div v-if="!element.data">
              <feather-icon
                class="mr-50"
                icon="PlusIcon"
              />
              <span>Выбрать</span>
            </div>
            <div v-else>
              <feather-icon
                class="mr-50"
                icon="Edit3Icon"
              />
              <span>Выбрать другое</span>
            </div>
          </b-button>
        </div>
      </template>
    </exericse-editor-modal>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import ExericseEditorModal from '@/components/new_courses/course/theme/lesson/step/components/elements/exercise/ExericseEditorModal.vue'
import StreamExerciseQuestions from '@/components/video-stream/actions/StreamExerciseQuestions.vue'

export default {
  name: 'StepExercise',
  components: {
    StreamExerciseQuestions, ExericseEditorModal, BButton,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    default: {
      name: '',
      type: null,
      exercisesContents: [],
    },
    updateExercise: 0,
  }),
  watch: {
    element() {
      this.setExercise()
    },
  },
  beforeMount() {
    this.setExercise()
  },
  methods: {
    async setExercise() {
      try {
        this.element.data = JSON.parse(this.element.data)
        // eslint-disable-next-line no-empty
      } catch (err) {}
      if (!this.element.exercise && !this.element.data) this.element.exercise = this.default
      else if (!this.element.exercise) {
        await this.loadExercise()
      }
    },
    async loadExercise() {
      this.element.exercise = await this.$http.get(`/exercises/view?id=${parseInt(this.element.data, 10)}&expand=exercisesContents`)
      this.updateExercise += 1
    },
    async exerciseSelected(exercise) {
      this.element.data = exercise.id
      this.element.exercise = exercise
      this.updateExercise += 1
    },
  },
}
</script>

<style scoped>

</style>
