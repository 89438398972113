<template>
  <div class="mb-1">
    <template v-if="editing">
      <b-form-file
        v-model="element.file"
        accept="image/*"
        :placeholder="inputPlaceholder"
        :drop-placeholder="$t('components.file-input.drop-file-here')"
        :browse-text="$t('components.file-input.browse')"
        @change="onImageFileChange"
      />
      <b-img
        v-if="imagePreviewUrl"
        :src="imagePreviewUrl"
        class="mt-1 image-preview small"
      />
      <store-image
        v-else-if="element.data"
        :image-url="element.image.full_url"
        image-class="mt-1 image-preview small"
      />
    </template>
    <template v-else-if="!element.disabled">
      <template v-if="element.data">
        <step-small-board
          v-if="board"
          :image-id="element.data"
          :image-url="element.image.full_url"
          :hometask-id="hometaskId"
        />
        <store-image
          v-else
          :image-url="element.image.full_url"
          image-class="image-preview"
          wrapper-class="text-center"
        />
      </template>
      <template v-else-if="imagePreviewUrl">
        <b-img
          :src="imagePreviewUrl"
          class="mt-1 image-preview"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { BImg, BFormFile } from 'bootstrap-vue'
import StoreImage from '@/components/store/StoreImage.vue'
import StepSmallBoard from '@/components/stream-courses/course/step/StepSmallBoard.vue'
import I18n from '@/libs/i18n/index'

export default {
  name: 'StepImage',
  components: {
    StepSmallBoard, StoreImage, BImg, BFormFile,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
    },
    board: {
      type: Boolean,
      default: false,
    },
    inputPlaceholder: {
      type: String,
      default: I18n.t('components.file-input.chose-file'),
    },
    hometaskId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    imagePreviewUrl: null,
  }),
  watch: {
    element() {
      this.setData()
    },
  },
  beforeMount() {
    this.setData()
  },
  methods: {
    onImageFileChange(e) {
      const file = e.target.files[0]
      this.imagePreviewUrl = URL.createObjectURL(file)
    },
    setData() {
      try {
        this.element.data = JSON.parse(this.element.data)
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
  },

}
</script>

<style scoped>

</style>
