<template>
  <div class="mb-1">
    <div v-if="editing">
      <b-form-file
        v-model="element.file"
        accept="audio/*"
        :placeholder="inputPlaceholder"
        :drop-placeholder="$t('components.file-input.drop-file-here')"
        :browse-text="$t('components.file-input.browse')"
        @change="onImageFileChange"
      />
      <store-audio
        v-if="preview"
        :url="preview"
      />
      <store-audio
        v-else-if="element.data"
        :store-id="element.data"
      />
    </div>
    <div v-else-if="!element.disabled">
      <store-audio
        v-if="element.data"
        :store-id="element.data"
      />
      <store-audio
        v-else-if="preview"
        :url="preview"
      />
    </div>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue'
import StoreAudio from '@/components/store/StoreAudio.vue'
import I18n from '@/libs/i18n/index'

export default {
  name: 'StepAudio',
  components: {
    StoreAudio,
    BFormFile,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
    },
    inputPlaceholder: {
      type: String,
      default: I18n.t('components.file-input.chose-file'),
    },
  },
  data: () => ({
    preview: null,
  }),
  watch: {
    element() {
      this.setData()
    },
  },
  beforeMount() {
    this.setData()
  },
  methods: {
    onImageFileChange(e) {
      const file = e.target.files[0]
      this.preview = URL.createObjectURL(file)
    },
    setData() {
      try {
        this.element.data = JSON.parse(this.element.data)
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
  },

}
</script>

<style scoped>

</style>
