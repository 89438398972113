<template>
  <b-row>
    <b-col
      v-for="(answer, answer_key) in exerciseAnswers"
      :key="answer_key"
      :cols="type == 1 ? 6 : 12"
    >
      <b-card
        class="cursor-pointer answer-card shadow-none"
        :class="[ answerColor(answer), teacherHintClass(answer) ]"
        @click="selectAnswer(answer)"
      >
        <b-card-text class="d-flex align-items-center justify-content-center full-height">
          <store-image
            v-if="answer.image_id"
            :store-id="answer.image_id"
          />
          <p
            v-else
            class="mb-0 font-weight-bold font-medium-3"
            :class="{'text-white': answerColor(answer)}"
          >
            {{ answer.text }}
          </p>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import StoreImage from '@/components/store/StoreImage.vue'
import { teacherRole, userRoles } from '@/store/user'

export default {
  name: 'ExerciseAnswers',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    StoreImage,
  },
  props: {
    exerciseAnswers: {
      type: Array,
      required: true,
    },
    answerSelected: {
      type: Function,
      required: true,
    },
    answerRemoved: {
      type: Function,
      required: true,
    },
    answered: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Number,
      required: false,
      default: 1,
    },
    selectedAnswers: {
      type: Array,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    selectedJSONAnswers() {
      return this.selectedAnswers.map(answer => JSON.stringify(answer))
    },
  },
  methods: {
    isAnswerSelected(answer) {
      return this.selectedJSONAnswers.includes(JSON.stringify(answer))
    },
    selectAnswer(answer) {
      if (this.answered) return
      if (this.isAnswerSelected(answer)) {
        this.answerRemoved(answer)
      } else {
        this.answerSelected(answer)
      }
    },
    answerColor(answer) {
      let color = null
      if (this.isAnswerSelected(answer)) color = 'bg-warning'
      if (this.answered) {
        if (answer.isCorrect) color = 'bg-success'
        if (color === 'bg-warning' && !answer.isCorrect) color = 'bg-danger'
      }
      return color
    },
    teacherHintClass(answer) {
      if (!this.isTeacher) return null
      if (answer.isCorrect) return 'border-success'
      return null
    },
  },
}
</script>
