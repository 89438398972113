<template>
  <div>
    <h1 class="font-large-2 text-success text-center mt-2">
      {{ $t('page.video-stream.stream-exercises.labels.done.title') }}!
    </h1>
    <h3 class="font-medium-1 text-center">
      {{ $t('page.video-stream.stream-exercises.labels.done.subtitle') }}
    </h3>
    <b-card class="mt-4 w-75 mr-auto ml-auto">
      <b-card-title class="text-center">
        {{ $t('page.video-stream.stream-exercises.labels.done.correct_answers') }}
      </b-card-title>
      <b-card-text class="pt-2 pb-2 text-center">
        <span
          :class="correctAnswersPercentage < 50 ? 'text-danger' : 'text-success'"
          class="font-large-2 font-weight-bolder"
        >
          {{ correctAnswersPercentage }}%
        </span>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardText,
} from 'bootstrap-vue'

export default {
  name: 'ExerciseFinished',
  components: {
    BCard,
    BCardTitle,
    BCardText,
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    correct: {
      type: Number,
      required: true,
    },
  },
  computed: {
    correctAnswersPercentage() {
      return Math.round((100 * this.correct) / this.total)
    },
  },
}
</script>

<style scoped>

</style>
