<template>
  <div class="mb-1">
    <quill-editor
      v-if="editing"
      :id="`step-data-${element.type}`"
      v-model="element.data"
      :options="quillOptions"
      class="w-100"
      rows="3"
    />
    <div
      v-else-if="!element.disabled"
      v-html="element.data"
    />
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'StepContent',
  components: {
    quillEditor,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    quillOptions() {
      return {
        placeholder: this.$t('page.course.lesson-step.labels.text'),
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5] }],
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'link'],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
          ],
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true,
          },
        },
      }
    },
  },
  watch: {
    element() {
      this.setData()
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      try {
        this.element.data = JSON.parse(this.element.data)
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
  },
}
</script>

<style lang="scss">
.ql-snow {
  .ql-picker.ql-header {
    width: 110px;

    .ql-picker-label, .ql-picker-item {
      &[data-value="1"]::before {
        content: 'Заголовок 1'
      }
      &[data-value="2"]::before {
        content: 'Заголовок 2'
      }
      &[data-value="3"]::before {
        content: 'Заголовок 3'
      }
      &[data-value="4"]::before {
        content: 'Заголовок 4'
      }
      &[data-value="5"]::before {
        content: 'Заголовок 5'
      }
    }
  }
}
</style>
