<template>
  <div class="mt-1">
    <b-card border-variant="secondary">
      <div
        :key="playButton"
        class="d-flex align-items-center"
      >
        <b-button
          class="btn-icon mr-1"
          variant="primary"
          pill
          @click="toggleAudio"
        >
          <feather-icon
            size="20"
            :icon="paused() ? 'PlayIcon' : 'PauseIcon'"
          />
        </b-button>

        <b-button
          class="btn-icon mr-1"
          variant="outline-primary"
          pill
          size="sm"
          @click="restart()"
        >
          <feather-icon
            size="12"
            icon="RefreshCcwIcon"
          />
        </b-button>

        <vue-slider
          v-model="audioObj.currentTime"
          :min="0"
          :max="parseInt(audioObj.duration, 10)"
          :interval="1"
          contained
          class="flex-fill ml-50 mr-50"
          drag-on-click
          hide-label
          tooltip="none"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'

export default {
  name: 'StoreAudio',
  components: {
    BButton,
    VueSlider,
    BCard,
  },
  props: {
    storeId: {
      type: [String, Number],
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    audioObj: new Audio(),
    playButton: 0,
    updateInterval: null,
  }),
  computed: {
    theme() {
      return this.$store.state.appConfig.layout.skin
    },
  },
  watch: {
    audioObj: {
      deep: true,
      handler() {
        this.playButton += 1
      },
    },
  },
  beforeDestroy() {
    this.audioObj.pause()
    clearInterval(this.updateInterval)
  },
  async mounted() {
    let src = this.url
    if (!src) src = await this.getFile()

    this.audioObj.src = src
    this.audioObj.pause()
  },
  methods: {
    toggleAudio() {
      this.playButton += 1
      if (!this.paused()) {
        this.audioObj.pause()
        clearInterval(this.updateInterval)
      } else {
        this.play()
      }
    },
    play() {
      this.updateInterval = setInterval(() => {
        this.playButton += 1
        if (this.audioObj.ended) clearInterval(this.updateInterval)
      }, 1000)
      this.audioObj.play()
    },
    restart() {
      this.playButton += 1
      clearInterval(this.updateInterval)
      this.audioObj.currentTime = 0
      this.play()
    },
    paused() {
      return this.audioObj.paused
    },
    async getFile() {
      this.loading = true
      const { full_url } = await this.$lazyHttp.storeGet(this.storeId)
      this.loading = false
      // eslint-disable-next-line camelcase
      return full_url
    },
  },
}
</script>

<style scoped>

</style>
