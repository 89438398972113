<template>
  <b-card
    no-body
    class="sticky-top"
  >
    <div
      v-if="centered"
      class="mt-4 mb-1 text-center"
    >
      <feather-icon
        icon="BookIcon"
        size="60"
        class="mb-2"
      />
      <h3>
        Здесь пока пусто
      </h3>
      <p class="font-weight-light font-medium-2">
        Начните наполнение шага с одного из элементов
      </p>
    </div>
    <b-card-body
      class="p-0 d-flex flex-wrap"
      :class="centered ? 'flex-row justify-content-center' : 'flex-column'"
    >
      <b-button
        v-for="element in elements"
        :key="element.name"
        variant="flat-secondary"
        @click="addElement(element.name)"
      >
        <feather-icon
          size="20"
          class="mb-50"
          :icon="element.icon"
        />
        <p class="mb-0 font-small-3">
          {{ element.label }}
        </p>
      </b-button>

      <add-presentation-modal
        :step="step"
        @onUpdate="$emit('onUpdate')"
      >
        <template #activator="{show}">
          <b-button
            variant="flat-secondary"
            block
            @click="show"
          >
            <feather-icon
              size="20"
              class="mb-50"
              icon="AirplayIcon"
            />
            <p class="mb-0 font-small-3">
              Презентация
            </p>
          </b-button>
        </template>
      </add-presentation-modal>

    </b-card-body>
  </b-card>
</template>

<script>
import { BButton, BCard, BCardBody } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { adminRole, userRoles } from '../../../../../../../store/user'
import AddPresentationModal from './elements/presentation/AddPresentationModal.vue'

export default {
  name: 'StepToolsPanel',
  components: {
    AddPresentationModal,
    BCard,
    BCardBody,
    BButton,
  },
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isAdmin() {
      return this.userRole === adminRole
    },
    elements() {
      return [
        {
          name: 'name',
          label: 'Заголовок',
          icon: 'TypeIcon',
        },
        {
          name: 'text',
          label: 'Текст',
          icon: 'ListIcon',
        },
        {
          name: 'image',
          label: 'Изображение',
          icon: 'ImageIcon',
        },
        {
          name: 'audio',
          label: 'Аудиодорожка',
          icon: 'Volume2Icon',
        },
        {
          name: 'video',
          label: 'Видео',
          icon: 'FilmIcon',
        },
        {
          name: 'test',
          label: 'Тест',
          icon: 'CheckSquareIcon',
        },
        {
          name: 'exercise',
          label: 'Упражнение',
          icon: 'BookIcon',
        },
        {
          name: 'doc',
          label: 'Документ Word и PDF',
          icon: 'FileIcon',
        },
        {
          name: 'iframe',
          label: 'Веб-страница',
          icon: 'LinkIcon',
        },
      ]
    },
  },
  methods: {
    addElement(type) {
      this.$emit('addElement', type)
    },
  },
}
</script>

<style scoped lang="scss">
.sticky-top {
  top: 105px
}
.card-fullscreen {
  .sticky-top {
    top: 0px
  }
}
</style>
