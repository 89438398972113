<template>
  <b-card
    img-alt="course card"
    class="mb-1"
    no-body
    :border-variant="selected ? 'primary' : bordered ? 'secondary' : ''"
    :class="{'cursor-pointer': selectable || !noOpen}"
    @click="showMore()"
  >
    <b-card-body
      style="min-height: 100px"
      class="d-flex full-height"
    >
      <div
        v-if="draggable"
        class="step-drag-handler"
      >
        <feather-icon
          icon="MenuIcon"
          size="20"
        />
      </div>
      <div class="d-flex flex-column  flex-fill">
        <div class="d-flex align-items-start">
          <div>
            <b-card-title class="mb-50 d-flex align-items-center">
              <div v-if="name">
                {{ name }}
              </div>
              <div
                v-if="step.user"
                class="ml-1"
              >
                <span v-if="name">–</span>
                <b-avatar
                  size="18"
                  :src="step.user.avatar"
                  class="ml-1 mr-50 badge-minimal"
                  :title="step.user.username"
                />
                <span class="font-medium-1">{{ authorName }}</span>
              </div>
            </b-card-title>
            <b-card-text class="mb-50">
              <div
                v-if="!isHometaskStep"
                class="d-flex align-items-center"
              >
                <div v-if="!inSession">
                  <b-badge
                    v-if="!step.private"
                    variant="light-info"
                  >
                    <feather-icon icon="UnlockIcon" />
                    Публичное
                  </b-badge>
                  <b-badge
                    v-else
                    variant="light-warning"
                  >
                    <feather-icon icon="LockIcon" />
                    Личное
                  </b-badge>
                </div>
                <b-badge
                  v-else
                  variant="light-info"
                >
                  Карточка
                </b-badge>

                <div class="d-flex align-items-center">
                  <b-form-rating
                    v-model="step.average_rating"
                    no-border
                    readonly
                    show-value
                    variant="warning"
                    inline
                    precision="2"
                    class="pr-50"
                  />
                  <!--                  <span class="text-muted font-small-3">-->
                  <!--                    (оценок: {{ step.count_step_comments }})-->
                  <!--                  </span>-->
                </div>
              </div>
            </b-card-text>

          </div>
          <div class="flex-fill" />
          <b-badge
            v-if="lessonName"
            class="mt-50 mr-1"
            variant="info"
          >
            {{ lessonName }}
          </b-badge>
          <a
            v-if="selectable"
            href="#"
            style="z-index: 30"
            class="p-50"
            @click.stop.prevent="selectStep"
          >
            <div class="d-flex align-items-center">
              <span class="mr-1 text-body">
                {{ selected ? 'Отменить' : 'Выбрать' }}
              </span>
              <b-form-checkbox :checked="selected" />
            </div>
          </a>
          <b-dropdown
            v-else
            no-caret
            dropleft
            variant="flat-secondary"
            class="mx-0 mb-0 p-0 course-selector-list-item-button ml-auto"
            menu-class="dropdown-editable-list"
            toggle-class="p-50 rounded-pill"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
              />
            </template>
            <b-dropdown-item
              variant="primary"
              :to="isHometaskStep ? { name: 'cabinet.hometasks.task', params: { task_id: step.home_tasks_id } } : { name: 'cabinet.step', params: { step_id: step.id } }"
            >
              <feather-icon
                icon="ExternalLinkIcon"
                size="14"
                class="mr-1"
              />
              Подробнее
            </b-dropdown-item>
            <b-dropdown-divider v-if="canEdit && !removable" />
            <b-dropdown-item-button
              v-if="canEdit && !isHometaskStep"
              @click.stop.prevent="togglePrivate"
            >
              <feather-icon
                :icon="!step.private ? 'LockIcon' : 'UnlockIcon'"
                size="14"
                class="mr-1"
              />
              <span v-if="!step.private">Сделать личной</span>
              <span v-else>Сделать публичной</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="canEdit && !isHometaskStep && isAdmin"
              @click.stop.prevent="copyStep"
            >
              <feather-icon
                icon="CopyIcon"
                size="14"
                class="mr-1"
              />
              Копировать
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="canDelete && !removable"
              variant="danger"
              @click.stop.prevent="deleteStep"
            >
              <feather-icon
                icon="TrashIcon"
                size="14"
                class="mr-1"
              />
              {{ $t('components.button.labels.delete') }}
            </b-dropdown-item-button>
          </b-dropdown>
        </div>

        <div class="flex-fill" />

        <b-card-text>
          <div class="d-flex align-items-end flex-wrap">
            <div
              v-if="images.length"
              class="d-flex  mr-1"
            >
              <store-image
                v-for="image in first2Images"
                :key="`image-${image}`"
                :image-url="image.image.full_url"
                class="step-card-image mr-50"
                image-class="rounded"
              />
              <div
                v-if="images.length > 2"
                class="step-card-image justify-content-center rounded flex-fill d-flex align-items-center"
                style="background-color: #eee"
              >
                <span class="font-medium-1 font-weight-bold">+{{ images.length - 2 }}</span>
              </div>
            </div>

            <b-button
              v-if="step.subjects"
              size="sm"
              class="mr-50"
              variant="outline-secondary"
              @click.stop.prevent="$emit('subjectSelected', step.subject_id)"
            >
              {{ step.subjects.text }}
            </b-button>
            <b-button
              v-if="step.grade"
              size="sm"
              class="mr-50"
              variant="outline-secondary"
              @click.stop.prevent="$emit('gradeSelected', step.grade_id)"
            >
              {{ step.grade.name }}
            </b-button>
            <b-button
              v-for="tag in step.lessonStepsTags"
              :key="tag.id"
              size="sm"
              class="mr-50"
              variant="outline-secondary"
              @click.stop.prevent="$emit('tagSelected', tag)"
            >
              <span>{{ tag.tags.name }}</span>
            </b-button>

            <div class="flex-fill" />
            <div v-if="!removable && !isHometaskStep">
              <b-button
                v-if="step.isFavorite"
                size="sm"
                variant="flat-warning"
                title="Убрать из избранного"
                @click.stop.prevent="removeFromFavorites"
              >
                <feather-icon
                  icon="BookmarkIcon"
                  class="mr-50 fill-warning"
                />
                В избранном
              </b-button>
              <b-button
                v-else
                size="sm"
                variant="flat-primary"
                title="Добавить в избранное"
                @click.stop.prevent="addToFavorites"
              >
                <feather-icon
                  icon="BookmarkIcon"
                  class="mr-50"
                />
                В избранное
              </b-button>
            </div>

            <b-button
              v-if="removable"
              size="sm"
              variant="flat-danger"
              @click.stop.prevent="$emit('remove')"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              Удалить
            </b-button>
          </div>
        </b-card-text>
      </div>

    </b-card-body>

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Карточка №${step.id} будет удалена.`"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCardText, BAvatar, BButton, BDropdown, BDropdownItemButton, BDropdownDivider, BBadge, BFormRating, BDropdownItem, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ConfirmModal from '@/components/page-elements/modals/ConfirmModal.vue'
import { adminRole, teacherRole, userRoles } from '../../store/user'
import { getAllStepElementsByType, getUserFullName, toast } from '@/helpers'
import StoreImage from '@/components/store/StoreImage.vue'

export default {
  name: 'StepItem',
  components: {
    StoreImage,
    ConfirmModal,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BDropdownDivider,
    BBadge,
    BFormRating,
    BDropdownItem,
    BFormCheckbox,
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    noOpen: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    withPreview: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    lessonName: {
      type: String,
      default: '',
    },
    inSession: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMouseOver: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      userType: 'course/get_course_user_type',
      readyType: 'course/get_course_ready_type',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isAdmin() {
      return this.userRole === adminRole
    },
    canEdit() {
      if (this.isAdmin) return true
      return this.step.user_id === this.user.id
    },
    canDelete() {
      if (this.isAdmin) return true
      return this.step.user_id === this.user.id
    },
    elements() {
      return this.step.homeTasksStepElements || this.step.stepElements
    },
    name() {
      return this.step.name || 'Без названия'
    },
    images() {
      return getAllStepElementsByType(this.elements, 'image', true)
    },
    first2Images() {
      return this.images.slice(0, 2)
    },
    authorName() {
      if (this.step.user) {
        return getUserFullName(this.step.user)
      }
      return null
    },
    isHometaskStep() {
      return this.step.home_tasks_id
    },
  },
  methods: {
    ...mapActions({
      addStepToFavorites: 'course/addStepToFavorites',
      removeStepFromFavorites: 'course/removeFromFavorites',
      copyStepRequest: 'course/copyStep',
    }),
    deleteStep() {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$http.delete(`/lesson-steps/delete?id=${this.step.id}`)
              .then(() => {
                this.$emit('deleted', this.step)
              })
          }
        })
    },
    async togglePrivate() {
      await this.$http.put(`/lesson-steps/update?id=${this.step.id}`, { ...this.step, private: !this.step.private })
      this.step.private = !this.step.private
    },
    async copyStep() {
      const copy = await this.copyStepRequest(this.step.id)
      if (copy?.id) {
        toast({ title: 'Копия создана', text: 'Вы были перенаправлены на страницу копии', type: 'success' })
        this.$router.push({ name: 'cabinet.step', params: { step_id: copy.id } })
      }
    },
    showMore() {
      if (this.withPreview) this.$emit('onPreview')
      else if (!this.noOpen) {
        this.$router.push({ name: 'cabinet.step', params: { step_id: this.step.id } })
        // window.open(routeData.href, '_blank')
      }
    },
    selectStep() {
      this.$emit('onSelect', this.step)
    },
    async addToFavorites() {
      this.step.isFavorite = true
      await this.addStepToFavorites(this.step.id)
    },
    async removeFromFavorites() {
      this.step.isFavorite = false
      await this.removeStepFromFavorites(this.step.id)
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  min-height: 150px;

  .step-card-image {
    width: 100px;
  }
}
</style>
