export default {
  computed: {
    isUsersCourse() {
      return this.$route.name === 'cabinet.users-course' || this.$route.name === 'cabinet.courses'
    },
    isReadyCourse() {
      return this.$route.name === 'cabinet.course' || this.$route.name === 'cabinet.ready-courses'
    },
  },
}
