<template>
  <div class="mb-1">
    <template v-if="editing">
      <b-form-file
        v-model="element.file"
        accept=".doc, .docx, application/pdf, .txt, .odt, .ppt, .pptx"
        :placeholder="inputPlaceholder"
        :drop-placeholder="$t('components.file-input.drop-file-here')"
        :browse-text="$t('components.file-input.browse')"
      />
      <store-document
        v-if="element.data"
        class="mt-2"
        :store-id="element.data"
        wrapper-class="text-center"
      />
    </template>
    <template v-else-if="!element.disabled">
      <store-document
        :store-id="element.data"
        wrapper-class="text-center"
      />
    </template>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue'
import I18n from '@/libs/i18n/index'
import StoreDocument from '@/components/store/StoreDocument.vue'

export default {
  name: 'StepDoc',
  components: {
    StoreDocument,
    BFormFile,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
    },
    board: {
      type: Boolean,
      default: false,
    },
    inputPlaceholder: {
      type: String,
      default: I18n.t('components.file-input.chose-file'),
    },
  },
  data: () => ({

  }),
  watch: {
    element() {
      this.setData()
    },
  },
  beforeMount() {
    this.setData()
  },
  methods: {
    setData() {
      try {
        this.element.data = JSON.parse(this.element.data)
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
  },

}
</script>

<style scoped>

</style>
