<template>
  <div>
    <b-card
      no-body
      class="mb-1"
    >
      <b-input-group class="w-100">
        <b-input-group-prepend is-text>
          <feather-icon
            icon="SearchIcon"
            class="text-muted"
          />
        </b-input-group-prepend>
        <b-form-input
          v-model="searchQuery"
          placeholder="Поиск..."
          @input="!searchQuery ? getCourses() : ''"
          @keydown.enter="getCourses()"
        />
      </b-input-group>
    </b-card>

    <template v-if="loading">
      <skeletor
        v-for="(skeleton, key) in 4"
        :key="key"
        width="100%"
        height="150"
        class="mb-1"
      />
    </template>
    <template v-else>
      <div :key="forceUpdate">
        <course
          v-for="(course, index) in courses"
          :key="course.id"
          :course="course"
          :external="external"
          :selectable="selectable"
          :selected="selectedCourse && course.id === selectedCourse.id"
          @updated="crs => updateCourse(index, crs)"
          @deleted="() => getCourses()"
          @subjectSelected="subj => $emit('subjectSelected', subj)"
          @gradeSelected="grp => $emit('gradeSelected', grp)"
          @courseSelected="$emit('select', course)"
        />
        <div class="d-flex justify-content-between">
          <span class="font-medium-2 text-body">
            <span class="text-muted">Всего курсов: {{ meta.totalCount }} </span>
          </span>

          <list-pagination
            v-if="meta.totalCount / meta.perPage > 1"
            :page="meta.currentPage"
            :limit="meta.perPage"
            :total-number="meta.totalCount"
            :pagination-updated="pageChanged"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BCard, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Course from '@/components/new_courses/course/Course.vue'
import ListPagination from '@/components/courses/course/ListPagination.vue'

export default {
  name: 'CoursesList',
  components: {
    BCard, ListPagination, Course, BInputGroup, BInputGroupPrepend, BFormInput,
  },
  props: {
    filter: {
      type: Object,
      default: () => ({
        own: false,
        filterType: null,
        types: [],
        subjects: [],
        grades: [],
      }),
    },
    external: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectedCourse: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    searchQuery: '',
    meta: {
      currentPage: 1,
      pageCount: 0,
      perPage: 15,
      totalCount: 0,
    },
    courses: [],
    forceUpdate: 0,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  mounted() {
    this.getCourses()
  },
  methods: {
    updateCourse(index, course) {
      this.$set(this.courses, index, course)
      this.forceUpdate += 1
    },
    load() {
      this.getCourses()
    },
    pageChanged({ page, limit }) {
      if (page) this.meta.currentPage = page
      if (limit) this.meta.perPage = limit
      this.getCourses()
    },
    async getCourses() {
      const {
        searchQuery, filter, meta,
      } = this

      this.loading = true
      const { items, _meta } = await this.$http.get('/courses', {
        params: {
          filter_type: filter.filterType,
          name: searchQuery,
          subjects_id: filter.subjects.join(','),
          grade_id: filter.grades.join(','),
          limit: meta.perPage,
          page: meta.currentPage,
          expand: 'user.image,user.usersTeachers,image,subjects,grade',
        },
      })
      this.meta.pageCount = _meta.pageCount
      this.meta.totalCount = _meta.totalCount
      this.courses = items
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
F
