<template>
  <div>
    <div class="d-flex align-items-center">
      <span>Список вопросов</span>
      <div class="flex-fill" />
      <b-button
        v-if="editable"
        :variant="preview ? 'flat-primary' : 'flat-secondary'"
        @click="preview = !preview"
      >
        <feather-icon
          class="mr-50"
          :icon="preview ? 'EyeOffIcon' : 'EyeIcon'"
        />
        Предпросмотр
      </b-button>
    </div>
    <b-tabs v-model="selectedContent">
      <!-- Render Tabs, supply a unique `key` to each tab -->
      <b-tab
        v-for="(content, i) in contents"
        :key="'dyn-tab-' + i"
        :title="`${i + 1}`"
        nav-class="align-items-center"
      >
        <exercise-content
          :index="i"
          :edit="!preview && editable"
          :exercise="exercise"
          :content="content"
          @delete="deleteContent(i)"
          @updated="(value) => $set(contents[i], 'data', value)"
        />
      </b-tab>

      <!-- New Tab Button (Using tabs-end slot) -->
      <template #tabs-end>
        <b-button
          v-if="editable"
          class="btn-icon"
          size="sm"
          variant="flat-primary"
          pill
          @click.prevent="newTab"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </template>

      <!-- Render this if no tabs -->
      <template #empty>
        <div class="text-center">
          В данном упражнении пока нет вопросов<br>
          <b-button
            v-if="editable"
            class="mt-1"
            variant="flat-primary"
            @click="newTab"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            Добавить
          </b-button>
        </div>
      </template>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BButton,
} from 'bootstrap-vue'
import ExerciseContent from '@/components/exercise/contents/ExerciseContent.vue'

export default {
  name: 'CreateExerciseContents',
  components: {
    ExerciseContent,
    BTabs,
    BTab,
    BButton,
  },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    contents: [],
    selectedContent: null,
    preview: false,
  }),
  watch: {
    contents: {
      deep: true,
      handler(value) {
        this.$emit('contentsUpdated', value)
      },
    },
  },
  beforeMount() {
    this.contents = this.exercise.exercisesContents
  },
  methods: {
    deleteContent(index) {
      const content = this.contents[index]
      this.$emit('contentDeleted', content)
      this.contents.splice(index, 1)
      setTimeout(() => {
        this.selectedContent = this.contents.length - 1
      })
    },
    newTab() {
      let lastQuestion = null
      const lastContent = this.contents[this.contents.length - 1]
      if (lastContent) lastQuestion = lastContent.question
      this.contents.push({
        question: lastQuestion,
        number: this.contents.length,
        data: {
          questions: {
            answers: [],
            exercise: null,
            image_id: null,
            audio_id: null,
            image: { data: null },
            audio: { data: null },
            canMultipleCorrect: false,
          },
        },
      })
      setTimeout(() => {
        this.selectedContent = this.contents.length - 1
      })
    },
  },
}
</script>
