<template>
  <div class="p-50 flex-fill d-flex align-items-center">
    <p
      v-if="currentIndex"
      class="font-weight-bold mb-0"
    >
      <b-button
        variant="flat-success"
        class="font-medium-2"
        size="sm"
      >
        <feather-icon
          size="18"
          icon="CheckIcon"
          class="mr-50"
        />
        <span>{{ totalCorrect }}</span>
      </b-button>

      <b-button
        variant="flat-danger"
        class="font-medium-2"
        size="sm"
      >
        <feather-icon
          size="18"
          icon="XIcon"
          class="mr-50"
        />
        <span>{{ currentIndex - totalCorrect }}</span>
      </b-button>
    </p>
    <div class="flex-fill" />
    <b-button
      variant="flat-primary"
      class="font-medium-2"
      size="sm"
    >
      {{ currentIndex === exercisesNumber ? currentIndex : currentIndex + 1 }} / {{ exercisesNumber }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'ExerciseInfo',
  components: {
    BButton,
  },
  props: {
    exercisesNumber: {
      type: Number,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    totalCorrect: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
