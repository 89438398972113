import { render, staticRenderFns } from "./ExerciseEditor.vue?vue&type=template&id=56a4b51a&scoped=true&"
import script from "./ExerciseEditor.vue?vue&type=script&lang=js&"
export * from "./ExerciseEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56a4b51a",
  null
  
)

export default component.exports