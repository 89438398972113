<template>
  <b-card
    class="mb-0 exercise-editor-modal"
    no-body
    style="min-height: 80vh"
  >
    <b-row class="flex-fill">
      <b-col>
        <select-exercise
          v-if="tab === 0"
          :selected-exercise="selectedExercise"
          @openCreator="() => { tab = 1 }"
          @selected="exerciseUpdated"
        />
        <create-exercise
          v-else
          :existing-exercise="exercise"
          @openSelector="() => { tab = 0 }"
          @updated="exerciseUpdated"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import CreateExercise from '@/components/new_courses/course/theme/lesson/step/components/elements/exercise/editor/CreateExercise.vue'
import SelectExercise from '@/components/new_courses/course/theme/lesson/step/components/elements/exercise/editor/SelectExercise.vue'

export default {
  name: 'ExerciseEditor',
  components: {
    SelectExercise,
    CreateExercise,
    BCard,
    BRow,
    BCol,
  },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    tabs: [
      { title: 'Выбрать готовое' },
      { title: 'Создать новое' },
    ],
    tab: 0,
    selectedExercise: null,
  }),
  beforeMount() {
    if (this.exercise.id) {
      this.selectedExercise = { ...this.exercise }
    }
  },
  methods: {
    exerciseUpdated(exercise) {
      if (exercise.id) {
        this.selectedExercise = { ...exercise }
        this.$emit('selected', exercise)
      }
    },
    saveExercise() {
    },
  },
}
</script>

<style scoped>

</style>
