<template>
  <!-- form -->
  <div>
    <!-- name -->
    <b-form-group
      v-if="!course"
      label-for="name"
      :label="$t('page.courses.form.labels.name')"
    >
      <b-form-input
        id="name"
        v-model="form.courses_name"
        v-validate="'required'"
        name="name"
        :placeholder="$t('page.courses.form.labels.name')"
        autofocus
        :state="errors.first('name') ? false : null"
      />
      <small class="text-danger">{{ errors.first('name') }}</small>
    </b-form-group>
    <!-- subject -->
    <b-form-group
      v-if="!course"
      :label="$t('page.courses.form.labels.subject')"
      label-for="subjects_id"
    >
      <v-select
        id="subjects_id"
        v-model="form.subjects_id"
        v-validate="'required'"
        :options="subjects"
        clearable
        label="text"
        class="d-block"
        :reduce="s => s.id"
        placeholder="Выберите предмет"
        name="subject"
        :state="errors.first('subject') ? false : null"
      >
        <template slot="no-options">
          {{ $t('components.select.no-items') }}
        </template>
      </v-select>
      <small class="text-danger">{{ errors.first('subject') }}</small>
    </b-form-group>
    <!-- grade -->
    <b-form-group
      v-if="!course"
      label="Класс"
      label-for="subject"
    >
      <v-select
        id="grade"
        v-model="form.grades_id"
        v-validate="'required'"
        :options="grades"
        clearable
        label="name"
        class="d-block"
        :reduce="s => s.id"
        placeholder="Выберите класс"
        name="grade"
        :state="errors.first('grade') ? false : null"
      >
        <template slot="no-options">
          {{ $t('components.select.no-items') }}
        </template>
      </v-select>
      <small class="text-danger">{{ errors.first('grade') }}</small>
    </b-form-group>
    <!-- file -->
    <b-form-group
      label="Файл Excel"
      label-for="excel_file"
    >
      <b-form-file
        id="excel_file"
        v-model="form.excel_file"
        v-validate="'required'"
        accept=".xls, .xlsx"
        :placeholder="$t('components.file-input.chose-file')"
        :drop-placeholder="$t('components.file-input.drop-file-here')"
        :browse-text="$t('components.file-input.browse')"
        name="excel_file"
        :state="errors.first('excel_file') ? false : null"
      />
      <small class="text-danger">{{ errors.first('excel_file') }}</small>
    </b-form-group>
    <!-- file -->
    <b-form-group
      label="Архив с папками"
      label-for="archive"
    >
      <b-form-file
        id="archive"
        v-model="form.archive"
        v-validate="'required'"
        accept=".zip, .rar, .7zip"
        :placeholder="$t('components.file-input.chose-file')"
        :drop-placeholder="$t('components.file-input.drop-file-here')"
        :browse-text="$t('components.file-input.browse')"
        name="archive"
        :state="errors.first('archive') ? false : null"
      />
      <small class="text-danger">{{ errors.first('archive') }}</small>
    </b-form-group>
    <div v-if="errorMessages">
      <b-alert
        v-for="(message, field) in errorMessages"
        :key="`error-${field}`"
        variant="danger"
        show
        class="mb-50"
      >
        <div class="alert-body">
          <p class="mb-0 font-weight-bold">
            {{ message }}
          </p>
          <p class="mb-0 font-weight-normal">
            Поле: {{ field }}
          </p>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BFormFile, BFormInput, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'UploadCourseForm',
  components: {
    BFormGroup,
    BFormFile,
    vSelect,
    BFormInput,
    BAlert,
  },
  props: {
    subjects: {
      type: Array,
      default: () => [],
    },
    grades: {
      type: Array,
      default: () => [],
    },
    course: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      excel_file: null,
      archive: null,
      courses_name: '',
      subjects_id: null,
      grades_id: null,
    },
    errorMessages: null,
  }),
  mounted() {
    this.form = {
      excel_file: null,
      archive: null,
      courses_name: this.course?.name ?? '',
      subjects_id: this.course?.subjects_id ?? null,
      grades_id: this.course?.grade_id ?? null,
    }
  },
  methods: {
    async uploadCourse() {
      this.errorMessages = null
      const data = await this.$validator.validate().then(async valid => {
        if (valid) {
          const formData = new FormData()
          Object.keys(this.form).forEach(key => {
            formData.append(key, this.form[key])
          })
          formData.append('additional_loading', this.course?.id ? 1 : 0)
          if (this.course?.id) formData.append('course_id', this.course?.id)

          const result = await this.$http.post('/courses/upload-pars', formData)
            .then(() => true)
            .catch(error => {
              this.errorMessages = error
              return false
            })
          return result
        }
        return false
      })
      return data
    },
  },
}
</script>
